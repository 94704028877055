import Swal from "sweetalert2";

var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
  url_json = url_json.local;
} else {
  url_json = url_json.production;
}

const Cursos = {
  obtenerCursos: async () => {
    let url = url_json + "/cursos";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerUnCurso: async (id) => {
    let url = url_json + "/curso/" + id;
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  eliminarCurso: async (id) => {
    Swal.fire({
      icon: "question",
      title: "¿Esta seguro que desea eliminar el curso?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let url = url_json + "/eliminar-curso/" + id;
        let request = {
          method: "DELETE",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };

        let response = await fetch(url, request);
        let res = await response.json();
        if (res.success) {
          Swal.fire(
            "Eliminación exitosa!",
            "Curso eliminado correctamente",
            "success"
          ).then(() => {
            window.location.replace("/cursos-diplomados");
          });
        } else {
          Swal.fire("Error!", "No se pudo eliminar el curso", "error").then(
            () => {
              window.location.reload();
            }
          );
        }
        return res;
      } else if (result.isDenied) {
        Swal.fire("Eliminación cancelada", "El campo quedo intacto", "succes");
      }
    });
  },
  ObtenerCursosEtiquetas: async () => {
    let url = url_json + "/cursos-etiqueta";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerUnCursoArea: async (area) => {
    let url = url_json + "/obtener-curso-area";
    let body = {
      area,
    };
    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerUnCursoNombre: async (nombre) => {
    let url = url_json + "/obtener-curso-nombre/" + nombre;
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerUnCursoProfesor: async (id) => {
    let url = url_json + "/obtener-curso-profesor";
    let body = {
      id,
    };
    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerUnCursoRelacion: async (id) => {
    let url = url_json + "/obtener-curso-relacion";
    let body = {
      id,
    };
    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerUnCursoDias: async (dias) => {
    let url = url_json + "/obtener-curso-dias";
    let body = {
      dias,
    };
    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
};

export default Cursos;
