import React, { Fragment } from 'react';
import Swal from 'sweetalert2';
import Downloader from 'js-file-downloader';

import './Igualdad.css';

import img1 from '../../Images/escritorio/Igualdad/1.png';
import movil1 from "../../Images/movil/Igualdad/1.png";

import img2 from '../../Images/escritorio/Igualdad/2.png';

import folleto from '../../Docs/Igualdad/folleto.pdf';
import protocolo from '../../Docs/Igualdad/protocolo.pdf';
import denuncia from '../../Docs/Igualdad/denuncia.pdf';

const download = documento => {
    new Downloader({ 
        url: documento
    }).then(function () {
        // console.log("descargando");
    }).catch(function (error) {
        Swal.fire(
            'Error',
            'Error al descargar archivo',
            'error'
        )
    }); 
}

const Igualdad = () => {
    return (
        <Fragment>
            {window.screen.width > 550 ? (
                <img src={img1} style={{width:"100%"}}/>
            ) : (
                <img src={movil1} style={{width:"100%"}}/>
            )}
            <div className='container contenedor_xch' style={{marginBottom:"50px"}}>
                <p className='titulo_1_nv center' style={{margin:"15px"}}>Igualdad</p>
                <p className='center subtitulo_igualdad'>
                    Creemos en la <b>igualdad</b>, en el <b>respeto</b> <br />
                    y en la <b>libertad</b> de ser lo que cada quien desea ser.
                </p>
                <p>
                    La Universidad de Medios Audiovisuales tiene el propósito de ser siempre un espacio 
                    seguro, libre y cómodo para todas y todos. Por eso tenemos una política de <b>respeto 
                    total</b> y de <b>cero tolerancia a todas las formas de violencia</b>, entre ellas 
                    la violencia de género.
                </p>
                <p>
                    Buscamos activamente asesoría, tanto por parte de instituciones del gobierno como del 
                    sector privado, sobre todo lo concerniente a este tema.
                </p>
                <p>
                    Uno de los apartados en los que trabajamos es la <b>igualdad de género</b> en la gestión, 
                    estructura y cultura para proveer un espacio de trabajo y de estudio libre de discriminación 
                    y de acoso u hostigamiento sexual.
                </p>
                <p>
                    Ponemos a tu disposición un <b>folleto con conceptos básicos</b> para hablar y actuar con 
                    respeto a todos y todas y comprender las bases de la igualdad.
                </p>
                <div className='boton_5_nv'>
                    <button onClick={() => download(folleto)}>
                        Descarga el folleto
                    </button>
                </div>
                <p className='center titulo_1_nv' style={{color:"#A80B38", margin:"5px"}}>Cómo lo hacemos</p>
                <p>
                    Somos parte del proyecto <b>Pactemos por la Igualdad</b>, de la <span>Secretaría de Igualdad Sustantiva 
                    entre Mujeres y Hombres</span> y la Cámara de Comercio de Guadalajara, que nos dan acompañamiento 
                    para que nuestras políticas de igualdad en el empleo sean congruentes con nuestro propósito 
                    y para guiarnos en la atención a los casos de acoso y hostigamiento sexual que pudieran 
                    presentarse en el ámbito académico y laboral de la universidad.
                </p>
                <p>
                    <b>1.</b> Tenemos un Comité de Ética, que define las políticas y da seguimiento a las quejas que 
                    puedan presentarse sobre estos temas.

                </p>
                <p>
                    <b>2.</b> Todas las personas que formamos parte del CAAV procuramos el uso de lenguaje incluyente 
                    y no sexista en toda nuestra comunicación.

                </p>
                <p>
                    <b>3.</b> Hemos diseñado un protocolo para atender presuntos casos que se presenten en el ámbito 
                    académico y laboral de la universidad, de acuerdo con un instrumento llamado violentómetro, 
                    que nos ha proveído la Secretaría de Igualdad Sustantiva. Este mecanismo irá sufriendo 
                    modificaciones conforme sea necesario.
                </p>
                <div className='boton_5_nv'>
                    <button onClick={() => download(protocolo)}>
                        Descarga el protocolo
                    </button>
                </div>
                <p>
                    La violencia de género son los actos dañinos dirigidos contra una persona o grupo de 
                    personas, en función de su sexo o de su género. Es decir, tratar mal a alguien por el 
                    simple hecho de ser mujer u hombre. Puede incluir daños físicos, sexuales, psicológicos, 
                    patrimoniales, económicos o de cualquier otra índole.
                </p>
                <p>
                    <b>Si en algún momento sientes temor, amenaza, acoso, hostigamiento o incomodidad 
                        por la conducta de una persona colaboradora, docente o estudiante del CAAV, 
                        dentro del ámbito académico o laboral de la universidad, puedes presentar tu 
                        denuncia ante nuestro Comité de Ética.</b>
                </p>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap", gap:"20px"}}>
                    <div className='boton_5_nv'>
                        <button onClick={() => download(denuncia)} style={{height:"32px"}} >
                            Descarga el formato de denuncia 
                        </button>
                    </div>
                    <div className='boton_6_nv'>
                        <a target='blank' href="mailto:igualdad@caav.mx">
                            <p>
                                Envía el formato de denuncia 
                            </p>
                        </a>
                    </div>
                </div>
                <p className='center titulo_1_nv' style={{color:"#A80B38", margin:"5px"}}>Violentómetro</p>
                <p className='center'>
                    Ésta es la herramienta que permite identificar y medir los grados de violencia. Será 
                    nuestra referencia para saber hasta dónde llega el alcance de la universidad y dónde 
                    deben intervenir las autoridades competentes.
                </p>
                <div className='row'>
                    <div className='col s12 m8 offset-m2'>
                        <img src={img2} alt="Violentometro CAAV" style={{width:"100%"}} />
                    </div>
                </div>
                <p className='center'>
                    <b>
                        Quien ama, respeta. <br />
                        El amor y la violencia no son compatibles.
                    </b>
                </p>
                <p className='center titulo_1_nv' style={{color:"#A80B38", margin:"5px"}}>Importante</p>
                <p className='center'>
                    Así como tenemos cero tolerancia al acoso y hostigamiento sexual, tenemos cero tolerancia 
                    a los rumores infundados. Si no se demuestra que alguien haya ejercido violencia de género, 
                    está prohibido difundir rumores difamatorios que puedan dañar la reputación de esa persona. 
                    Quienes difundan estos rumores también podrán recibir sanciones.
                </p>
            </div>
        </Fragment>
    );
};

export default Igualdad;