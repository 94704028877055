import React, { Fragment, useEffect, useState } from 'react';
import CryptoJS from "react-native-crypto-js";
import Swal from 'sweetalert2';

import PdfHelper from '../../Helpers/Pdf';

// tipos de logins
// 0 => horario
// 1 => horario examen
// 2 => calificaciones
// 3 => derecho a examne

// tipos de archivos
// 1 => licenciatura
// 2 => cuatrimestre


const EstudiantePDF = props => {
    const vista = [
        "HORARIOS",
        "HORARIO DE EXÁMENES",
        "CALIFICACIONES",
        "DERECHO A EXAMEN"
    ];
    let tipo = parseInt(window.atob(sessionStorage.getItem('_tipo')));

    const [flag, setFlag] = useState(true);
    const [pdfs, setPDFs] = useState([]);

    useEffect(() => {
        let session = sessionStorage.getItem('_session');
        let bytes  = CryptoJS.AES.decrypt(session, 'Y2Fhdg==');
        let originalText = bytes.toString(CryptoJS.enc.Utf8);

        if (originalText === 'sesion$iniciada&Y29udHJhc2XDsWE=' ) {
            Swal.fire({
                icon: 'success',
                title: 'Bienvenido :D',
                showConfirmButton: false,
                timer: 1000
            })

            obtenerPDF();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Acceso Denegado</br>Antes Inicia Sesión',
                showConfirmButton: false,
                timer: 1000
            })

            window.location.replace("/horario");
        }
    },[]);

    const obtenerPDF = async () => {
        let res = await PdfHelper.obtenerPdfCampo(tipo);
        if (res.success) {
            setPDFs(res.data);
            setFlag(false);
        } else {
            Swal.fire(
                'Error!',
                'No se pudo obtener la información, codigo: ' + res.code ,
                'error'
            ).then(() => {
                window.location.reload();
            })
        }
    }

    return (
        <div>
            <div className='contenedor_pdf center' style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                {flag ? (
                    <p>cargando...</p>
                ) : (
                    <div>
                        <div>
                            <p className='titulo_9' style={{textAlign:"center"}}>
                                {vista[tipo]}
                            </p> 
                            <p>
                                Si eres de 1º a 4º cuatrimestre, elige tu nivel,
                                o elige tu licenciatura si estás en quinto o más arriba.
                            </p>
                        </div>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap"}}>
                            {pdfs.map(pdf => (
                                pdf.tipo === 2 && (
                                    <a target="_blanck" href={pdf.archivo}>
                                        <div className="boton_pdf">
                                            <p>{pdf.titulo}</p>
                                        </div>
                                    </a>
                                )
                            ))}
                        </div>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap"}}>
                            {pdfs.map(pdf => (
                                pdf.tipo === 1 && (
                                    <a target="_blanck" href={pdf.archivo}>
                                        <div className="boton_pdf_2">
                                            <p>{pdf.titulo}</p>
                                        </div>
                                    </a>
                                )
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EstudiantePDF;

// export default compose(
//     firestoreConnect(() => {
//         let tipo = parseInt(window.atob(sessionStorage.getItem('_tipo')));
//         console.log(typeof tipo, tipo);
//         return [{ 
//             collection: 'pdf',
//             where: [
//                 ['campo', '==', tipo]
//             ],
//             orderBy: "titulo"
//         }]
//     }),
//     connect((state, props) => ({
//         pdf : state.firestore.ordered.pdf
//     }))
// )();