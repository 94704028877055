import React, { Fragment, useEffect, useState } from "react";
import { Carousel } from "@trendyol-js/react-carousel";
import Swal from "sweetalert2";

import "./Index.css";

import EnterateHelper from "../../Helpers/Enterate";

import CarruselImagenes from "../../Components/CarruselImagenes/CarruselImagenes";
import imagenes from "../../Components/CarruselImagenes/Imagenes/ImagenesCarruselIndex";

import video1 from "../../Images/escritorio/Index/video/video_1.m4v";
import imagenVideo from "../../Images/escritorio/Index/video/1.png";
//licenciaturas
import img1 from "../../Images/escritorio/Index/1.png";
import img1_1 from "../../Images/escritorio/Index/1_1.png";
import img2 from "../../Images/escritorio/Index/2.png";
import img2_1 from "../../Images/escritorio/Index/2_1.png";
import img3 from "../../Images/escritorio/Index/3.png";
import img3_1 from "../../Images/escritorio/Index/3_1.png";
import img4 from "../../Images/escritorio/Index/4.png";
import img4_1 from "../../Images/escritorio/Index/4_1.png";
// graduados
import imgEg1 from "../../Images/escritorio/Index/graduados/ArturoAlmanza.png";
import imgEg2 from "../../Images/escritorio/Index/graduados/AlejandroyCristina.png";
import imgEg3 from "../../Images/escritorio/Index/graduados/AlejandraMartinez.png";
import imgEg4 from "../../Images/escritorio/Index/graduados/FabriciodelaMora.png";
import imgEg5 from "../../Images/escritorio/Index/graduados/DanielaSilva.png";
import imgEg6 from "../../Images/escritorio/Index/graduados/NachoPonce.png";

import icono1 from "../../Images/escritorio/Cursos/iconos/1.png";
import icono2 from "../../Images/escritorio/Cursos/iconos/2.png";
import icono3 from "../../Images/escritorio/Cursos/iconos/3.png";
import icono4 from "../../Images/escritorio/Cursos/iconos/4.png";
import icono5 from "../../Images/escritorio/Cursos/iconos/5.png";
import icono6 from "../../Images/escritorio/Cursos/iconos/6.png";
import icono7 from "../../Images/escritorio/Cursos/iconos/7.png";
import icono8 from "../../Images/escritorio/Cursos/iconos/8.png";
import icono9 from "../../Images/escritorio/Cursos/iconos/9.png";
import icono10 from "../../Images/escritorio/Cursos/iconos/10.png";
import icono11 from "../../Images/escritorio/Cursos/iconos/11.png";
import icono12 from "../../Images/escritorio/Cursos/iconos/12.png";
import icono13 from "../../Images/escritorio/Cursos/iconos/13.png";

import Loader from "../../Components/Loader/Loader";

const Index = () => {
  const [flag, setFlag] = useState(false);
  const [noticias, setNoticias] = useState([]);

  // const gtag_report_conversion_poppop = (url) => {
  //   // proceso de admision
  //   var callback = function () {
  //     if (typeof url != "undefined") {
  //       var nuevaVentana = window.open();
  //       nuevaVentana.location.href = url;

  //       // window.location.replace(url)
  //     }
  //   };
  //   window.gtag("event", "conversion", {
  //     send_to: "AW-1004461178/8ajUCJzJuawYEPq4-94D",
  //     event_callback: callback,
  //   });
  //   return false;
  // };

  useEffect(() => {
    // Swal.fire({
    //   html:
    //     "<p class='texto_pop'><span class='titulo_4_nv titulo_bold'>Entra a las charlas informativas</span></br>" +
    //     "para saber más sobre nuestras carreras.</p>",
    //   showCloseButton: true,
    //   showCancelButton: false,
    //   focusConfirm: false,
    //   confirmButtonText: '<i class="fa fa-whatsapp"></i> Pide aquí tu acceso',
    //   confirmButtonColor: "#02B5C1",
    // }).then((res) => {
    //   if (res.isConfirmed) {
    //     // redireccionamos
    //     // gtag_report_conversion_poppop(
    //     //   "https://api.whatsapp.com/send?phone=3322351431"
    //     // );
    //     var nuevaVentana = window.open();
    //     nuevaVentana.location.href =
    //       "https://api.whatsapp.com/send?phone=3322351431";
    //   }
    // });
    obtenerNoticias();
  }, []);

  const estudiantes = [
    {
      nombre: "Daniela Silva",
      titulo: "Egresada del CAAV, de Cine Digital",
      informacion: (
        <div>
          <p>...</p>
          <p>...</p>
        </div>
      ),
      video: "https://youtu.be/V7XmoIOWj58",
      imagen: imgEg5,
    },
    {
      nombre: "Nacho Ponce",
      titulo: "Egresado del CAAV, de Animación.",
      informacion: (
        <div>
          <p>...</p>
          <p>...</p>
        </div>
      ),
      video: "https://youtu.be/So-4kwiE39M",
      imagen: imgEg6,
    },
    {
      nombre: "Arturo Almanza",
      titulo: "Egresado del CAAV, de Animación.",
      informacion: (
        <div>
          <p>
            En abril de 2013 obtuvo una beca en la Vancouver Film School, una de
            las diez mejores universidades de animación en el mundo.
          </p>
          <p>
            Actualmente vive en Vancouver y trabaja para el estudio Proof Inc.
          </p>
        </div>
      ),
      video: "https://youtu.be/jXT0lZTZeXo",
      imagen: imgEg1,
    },
    {
      nombre: "Alejandro Torres y Cristina León",
      titulo: "Egresados del CAAV, de Cine Digital.",
      informacion: (
        <div>
          <p>
            En 2012, fundaron Clack Audiovisual, y producen contenidos para
            marcas como Red Bull, Nike y Vans.
          </p>
          <p>Actualmente viven en la CDMX.</p>
        </div>
      ),
      video: "https://youtu.be/vbceWYe_hBY",
      imagen: imgEg2,
    },
    {
      nombre: "Alejandra Martínez",
      titulo: "Egresada del CAAV, de Publicidad.",
      informacion: (
        <div>
          <p>
            Trabaja en Terán/TBWA, una de las agencias de publicidad más
            importantes de México y el mundo, para marcas como Palacio de
            Hierro, Nissan, GNP y BBVA Bancomer.
          </p>
        </div>
      ),
      video: "https://youtu.be/6lD8pHQAPJs",
      imagen: imgEg3,
    },
    {
      nombre: "Fabriccio de la Mora",
      titulo: "Egresado del CAAV, de Multimedia.",
      informacion: (
        <div>
          <p>
            Trabaja como desarrollador de software y es creador
            multidisciplinario. Ha creado muchas apps, entre las que están 8Bit
            Drums Pro y Explorador Urbano. Ha trabajado en proyectos como Par
            Ásito y Feedback Workship.
          </p>
        </div>
      ),
      video: "https://youtu.be/rGduWnY36cc",
      imagen: imgEg4,
    },
  ];

  const obtenerNoticias = async () => {
    let res = await EnterateHelper.obtenerNoticias();
    if (res.success) {
      setNoticias(res.data);
      setFlag(true);
      setTimeout(() => {
        // var elems = document.querySelectorAll('.materialboxed');
        // M.Materialbox.init(elems);
      }, 500);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener los profesores, codigo: " + res.code,
        "error"
      );
    }
  };

  return (
    <Fragment>
      <video
        style={{ width: "100%" }}
        id="video"
        autoPlay
        muted="muted"
        loop
        poster={imagenVideo}
      >
        <source src={video1} type="video/mp4" />
      </video>
      <div className="container center">
        <p className="encabezadosCaav" style={{ marginTop: "15px" }}>
          <span className="encabezadosMonserrat">
            Elige la licenciatura que guíe tu camino creativo.
          </span>
          <br />
          Todas te llevan a la realización.
        </p>
        <div className="row licenciaturas_index_contenedor">
          <a href="/licenciatura-en-cine">
            <div className="col s6 m6 l3">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <div
                  className="imagen_efecto_1"
                  style={{ borderRadius: "10px" }}
                >
                  <img
                    src={img1}
                    alt="Licenciatura en Cine Digital"
                    className="efecto_licenciatura"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </div>
                <img
                  src={img1_1}
                  alt="Licenciatura en Cine Digital"
                  style={{
                    width: "100%",
                    position: "absolute",
                    zIndex: "1",
                    bottom: "0",
                  }}
                />
                {/* <CineImagen /> */}
              </div>
            </div>
          </a>
          <a href="/licenciatura-en-animacion">
            <div className="col s6 m6 l3">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <div
                  className="imagen_efecto_1"
                  style={{ borderRadius: "10px" }}
                >
                  <img
                    src={img2}
                    alt="Licenciatura en Animación"
                    className="efecto_licenciatura"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </div>
                <img
                  src={img2_1}
                  alt="Licenciatura en Cine Digital"
                  style={{
                    width: "100%",
                    position: "absolute",
                    zIndex: "1",
                    bottom: "0",
                  }}
                />
                {/* <AnimacionImagen /> */}
              </div>
            </div>
          </a>
          <a href="/multimedia">
            <div className="col s6 m6 l3">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <div
                  className="imagen_efecto_1"
                  style={{ borderRadius: "10px" }}
                >
                  <img
                    src={img3}
                    alt="Licenciatura en Multimedia, Arte Digital y Medios Interactivos"
                    className="efecto_licenciatura"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </div>
                <img
                  src={img3_1}
                  alt="Licenciatura en Cine Digital"
                  style={{
                    width: "100%",
                    position: "absolute",
                    zIndex: "1",
                    bottom: "0",
                  }}
                />
                {/* <MultimediaImagen /> */}
              </div>
            </div>
          </a>
          <a href="/licenciatura-marketing-digital">
            <div className="col s6 m6 l3">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <div
                  className="imagen_efecto_1"
                  style={{ borderRadius: "10px" }}
                >
                  <img
                    src={img4}
                    alt="Licenciatura en Marketing Digital Creativo"
                    className="efecto_licenciatura"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </div>
                <img
                  src={img4_1}
                  alt="Licenciatura en Cine Digital"
                  style={{
                    width: "100%",
                    position: "absolute",
                    zIndex: "1",
                    bottom: "0",
                  }}
                />
                {/* <MarketingImagen /> */}
              </div>
            </div>
          </a>
        </div>
      </div>

      <div
        style={{
          background: "#F8F8F8",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <p className="titulo_1_nv" style={{ textAlign: "center", margin: "0" }}>
          Entérate
        </p>
        {!flag ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="container">
            {window.screen.width <= 980 ? (
              <Carousel
                className="carrusel_propio_1 center_flex"
                show={1}
                slide={1}
                transition={0.5}
                responsive={true}
              >
                {noticias.map((noticia) =>
                  noticia.url ? (
                    <a href={noticia.url}>
                      <div
                        className="floating pointer"
                        style={{
                          padding: "0px 10px",
                          margin: "10px 0px",
                        }}
                      >
                        <img
                          src={noticia.imagen}
                          alt="CAAV noticias"
                          style={{ width: "100%", borderRadius: "10px" }}
                        />
                      </div>
                    </a>
                  ) : (
                    <div
                      className="floating"
                      style={{ padding: "0px 10px", margin: "10px 0px" }}
                    >
                      <img
                        src={noticia.imagen}
                        alt="CAAV noticias"
                        style={{ width: "100%", borderRadius: "10px" }}
                      />
                    </div>
                  )
                )}
              </Carousel>
            ) : (
              <Carousel
                className="carrusel_propio_1 center_flex"
                show={3}
                slide={1}
                transition={0.5}
                responsive={true}
              >
                {noticias.map((noticia) =>
                  noticia.url ? (
                    <a href={noticia.url}>
                      <div
                        className="floating pointer"
                        style={{
                          padding: "0px 10px",
                          margin: "10px 0px",
                        }}
                      >
                        <img
                          src={noticia.imagen}
                          alt="CAAV noticias"
                          style={{ width: "100%", borderRadius: "10px" }}
                        />
                      </div>
                    </a>
                  ) : (
                    <div
                      className="floating"
                      style={{ padding: "0px 10px", margin: "10px 0px" }}
                    >
                      <img
                        src={noticia.imagen}
                        alt="CAAV noticias"
                        style={{ width: "100%", borderRadius: "10px" }}
                      />
                    </div>
                  )
                )}
              </Carousel>
            )}
          </div>
        )}
      </div>

      <div className="center container">
        <p
          className="titulo_1_nv"
          style={{ marginTop: "10px", marginBottom: "0px" }}
        >
          Lo que nos hace únicos
        </p>
        <p>
          Nuestro amor por las historias <br />
          y por el arte de contarlas con vividez, <br />
          con imaginación, con lenguajes nuevos <br />
          y creativos, que a lo largo del tiempo <br />
          hemos ido enriqueciendo y perfeccionando.
        </p>
        <p>
          Nuestra historia de casi 30 años <br />
          de ir un paso adelante: <br />
          hemos marcado el camino, <br />y lo seguiremos haciendo.
        </p>
        <h1
          className="titulo_4_nv"
          style={{ margin: "0", fontWeight: "bold", marginBottom: "10px" }}
        >
          CAAV
        </h1>
        <div className="row">
          {/* <div className="col s12 m6" style={{ padding: "10px" }}>
            <div className="contenedor_media">
              <iframe
                className="media_contenido"
                src="https://www.youtube.com/embed/Ae3LGJyob6I"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style={{ borderRadius: "10px" }}
              />
            </div>
            <p className="titulo_2_nv">
              Daniel Varela, nuestro director, te habla del CAAV
            </p>
          </div> */}
          <div className="col s12" style={{ padding: "10px" }}>
            <div className="contenedor_media">
              <iframe
                className="media_contenido"
                src="https://www.youtube.com/embed/5nScuZaCCBM?si=gXDCv5ERqVB4sgNu"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style={{ borderRadius: "10px" }}
              />
            </div>
            <p className="titulo_2_nv">
              Conoce en un minuto la esencia del CAAV
            </p>
          </div>
        </div>
      </div>

      <div className="container center">
        <p className="encabezadosCaav" style={{ marginBottom: "10px" }}>
          <span className="encabezadosMonserrat">El set donde suceden </span>
          <br />
          nuestras historias
        </p>
      </div>

      <div className="container">
        <CarruselImagenes imagenes={imagenes} />
      </div>

      <div className="container center" style={{ marginTop: "40px" }}>
        <h1
          className="titulo_1_nv"
          style={{ marginBottom: "0px", marginTop: "10px" }}
        >
          Cursos, talleres y diplomados
        </h1>
        <p>
          Tenemos más de 130 opciones para elegir. <br />
          No necesitas ser alumno del CAAV para tomarlos. <br />
          Son para todo tipo de público, a partir de los 15 años.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="boton_1_nv" style={{ background: "#00B5C1" }}>
            <a href="/cursos-diplomados">
              <p>¡Conócelos todos!</p>
            </a>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div
          className="flex_d1 container contenedor_mediano center"
          style={{ gap: "20px" }}
        >
          <div className="col s6 m4 l2 background_1 etiquetas-cursos">
            <a href="/cursos-area/animacion-anime-y-comic">
              <p style={{ marginTop: "3px" }}>
                Animación,
                <br />
                Animé y Cómic
              </p>
              <img
                src={icono1}
                alt="Animación, Animé y Cómic"
                style={{ width: "100%" }}
              />
            </a>
          </div>
          <div className="col s6 m4 l2 background_4 etiquetas-cursos">
            <a href="/cursos-area/artes-plásticas">
              <p style={{ marginTop: "3px" }}>
                Artes
                <br />
                plásticas
              </p>
              <img
                src={icono4}
                alt="Artes plásticas"
                style={{ width: "100%" }}
              />
            </a>
          </div>
          <div className="col s6 m4 l2 background_13 etiquetas-cursos">
            <a href="/cursos-area/artes-escenicas">
              <p style={{ marginTop: "3px" }}>
                Artes
                <br />
                escénicas
              </p>
              <img
                src={icono13}
                alt="Artes escénicas"
                style={{ width: "100%" }}
              />
            </a>
          </div>
          <div className="col s6 m4 l2 background_2 etiquetas-cursos">
            <a href="/cursos-area/audio-y-voz">
              <p style={{ marginTop: "3px" }}>
                Audio
                <br />y Voz
              </p>
              <img src={icono2} alt="Audio y Voz" style={{ width: "100%" }} />
            </a>
          </div>
          <div className="col s6 m4 l2 background_3 etiquetas-cursos">
            <a href="/cursos-area/cine-y-video">
              <p style={{ marginTop: "3px" }}>
                Cine
                <br />y Video
              </p>
              <img src={icono3} alt="Cine y Video" style={{ width: "100%" }} />
            </a>
          </div>
          <div className="col s6 m4 l2 background_5 etiquetas-cursos-2">
            <a href="/cursos-area/diseño">
              <p style={{ marginTop: "15px" }}>Diseño</p>
              <img src={icono5} alt="Diseño" style={{ width: "100%" }} />
            </a>
          </div>
          <div className="col s6 m4 l2 background_6 etiquetas-cursos">
            <a href="/cursos-area/emprendimiento">
              <p style={{ marginTop: "15px" }}>Emprendimiento</p>
              <img
                src={icono7}
                alt="Emprendimiento"
                style={{ width: "100%" }}
              />
            </a>
          </div>
          <div className="col s6 m4 l2 background_7 etiquetas-cursos">
            <a href="/cursos-area/escritura">
              <p style={{ marginTop: "15px" }}>Escritura</p>
              <img src={icono8} alt="Escritura" style={{ width: "100%" }} />
            </a>
          </div>
          <div className="col s6 m4 l2 background_8 etiquetas-cursos">
            <a href="/cursos-area/fotografia">
              <p style={{ marginTop: "15px" }}>Fotografía</p>
              <img src={icono9} alt="Fotografía" style={{ width: "100%" }} />
            </a>
          </div>
          <div className="col s6 m4 l2 background_10 etiquetas-cursos">
            <a href="/cursos-area/marketing-y-la-web">
              <p style={{ marginTop: "3px" }}>
                Marketing
                <br />y la Web
              </p>
              <img
                src={icono11}
                alt="Marketing y la Web"
                style={{ width: "100%" }}
              />
            </a>
          </div>
          <div className="col s6 m4 l2 background_11 etiquetas-cursos">
            <a href="/cursos-area/multimedia-y-videojuegos">
              <p style={{ marginTop: "3px" }}>
                Multimedia
                <br />y Videojuegos
              </p>
              <img
                src={icono12}
                alt="Multimedia y Videojuegos"
                style={{ width: "100%" }}
              />
            </a>
          </div>
          <div className="col s6 m4 l2 background_12 etiquetas-cursos">
            <a href="/cursos-area/suite-de-adobe">
              <p style={{ marginTop: "3px" }}>
                Suite
                <br />
                de Adobe
              </p>
              <img
                src={icono6}
                alt="Suite de Adobe"
                style={{ width: "100%" }}
              />
            </a>
          </div>
          <div className="col s6 m4 l2 background_9 etiquetas-cursos">
            <a href="/cursos-area/teoria-del-arte">
              <p style={{ marginTop: "3px" }}>
                Teoría
                <br />
                del arte
              </p>
              <img
                src={icono10}
                alt="Teoría del arte"
                style={{ width: "100%" }}
              />
            </a>
          </div>
        </div>
      </div>

      <div style={{ margin: "20px 0px 40px 0px" }}>
        <p
          className="encabezadosCaav margen_movil_index"
          style={{
            textAlign: "center",
            marginTop: "40px",
            marginBottom: "10px",
          }}
        >
          Egresados CAAV <br />
          <span className="encabezadosMonserrat">
            y sus historias de realización
          </span>
        </p>
        {window.screen.width <= 980 ? (
          <Carousel
            className="carrusel_propio"
            show={1}
            slide={1}
            transition={0.5}
            responsive={true}
          >
            {estudiantes.map((estudiante) => (
              <div className="contenedor_egresados">
                <div
                  style={{
                    background: "#fff",
                    height: "370px",
                    margin: "10px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <a target="blanck" href={estudiante.video}>
                      <img
                        src={estudiante.imagen}
                        alt="caav egresados"
                        style={{
                          width: "90%",
                          borderRadius: "25px",
                          margin: "0 5%",
                        }}
                      />
                    </a>
                  </div>
                  <div style={{ margin: "0 5%" }}>
                    <p className="titulo_2_nv" style={{ margin: "0px" }}>
                      {estudiante.nombre}
                    </p>
                    <p className="titulo_3_nv" style={{ margin: "0px" }}>
                      {estudiante.titulo}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel
            className="carrusel_propio"
            show={2}
            slide={2}
            transition={0.5}
            responsive={true}
          >
            {estudiantes.map((estudiante) => (
              <div
                className="contenedor_2_index"
                style={{ background: "#ffffff" }}
              >
                <div
                  style={{
                    background: "#fff",
                    height: "auto",
                    margin: "10px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <a target="blanck" href={estudiante.video}>
                      <img
                        src={estudiante.imagen}
                        alt="caav egresados"
                        style={{
                          width: "80%",
                          borderRadius: "25px",
                          margin: "0 10%",
                        }}
                      />
                    </a>
                  </div>
                  <div style={{ margin: "0 10%" }}>
                    <p className="titulo_2_nv" style={{ margin: "0px" }}>
                      {estudiante.nombre}
                    </p>
                    <p className="titulo_3_nv" style={{ margin: "0px" }}>
                      {estudiante.titulo}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>

      {/* <div className='flex_padre_1_nv' style={{background:"#F8F8F8"}}>
                <div className='flex_hijo_1_nv'>
                    <img src={img5} alt="Licenciatura Audiovisuales" style={{width:"100%"}} />
                </div>
                <div className='flex_hijo_1_nv contenedor_3_index'>
                    <h1 className='titulo_1_nv' style={{marginBottom:"0px", marginTop:"10px"}}>Cursos y diplomados</h1>
                    <p>
                        Tenemos más de 130 cursos y diplomados, <br />
                        clasificados en ocho áreas de conocimiento.
                    </p>
                    <p>
                        No necesitas ser alumno del CAAV para tomarlos, <br />
                        ni tener conocimientos previos para muchos de ellos, <br />
                        ya que están pensados para todo tipo de público, <br />
                        con diferentes intereses y de distintas edades.
                    </p>
                    <div className='boton_1_nv'>
                        <a href="/cursos-diplomados">
                            <p>
                                Conócelos todos
                            </p>
                        </a>
                    </div>
                </div>
            </div> */}

      <div className="center">
        <p className="encabezadosCaav">
          Estamos en la Colonia Americana <br />
          <span className="encabezadosMonserrat">
            Declarada Barrio Mágico y el Más Cool del Mundo
          </span>
        </p>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.962817160152!2d-103.37224418507336!3d20.671091686193154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae058d2f4fd3%3A0x209823d3fba8d0af!2sCAAV%20Universidad%20de%20Medios%20Audiovisuales!5e0!3m2!1ses-419!2smx!4v1643735295418!5m2!1ses-419!2smx"
        style={{ width: "100%", height: "500px", border: "none" }}
        title="mapa caav"
      ></iframe>
    </Fragment>
  );
};

export default Index;
