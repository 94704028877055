import Swal from 'sweetalert2';

var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
    url_json = url_json.local;
} else {
    url_json = url_json.production;
}

const VideosHelper = {
    ObtenerVideos: async () => {
        let url = url_json + "/videos";
        let request = {
            method: 'GET',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    },
    ObtenerUnVideo: async (id) => {
        let url = url_json + "/video/" + id;
        let request = {
            method: 'GET',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    },
    ObtenerVideosCampo: async (id) => {
        let url = url_json + "/video-campo/" + id;
        let request = {
            method: 'GET',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    },
    AgregarVideo: async (state) => {
        let url = url_json + "/agregar-video"
        let body = {
            campo: state.campo,
            archivo: state.archivo,
            titulo: state.titulo,
            vista: state.vista
        }
        let request = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }
        let response = await fetch(url, request);
        let res = await response.json();
        if (res.success) {
            Swal.fire(
              'Inserción exitosa!',
              'campo guardado correctamente' ,
              'success'
            ).then(() => {
            //   window.location.reload();
            })
        } else {
            Swal.fire(
                'Error!',
                'No se pudo guardar el campo, codigo: ' + res.code ,
                'error'
            ).then(() => {
                // window.location.reload();
            })
        }
        return res;
    },
    ActualizarVideo: async (id, state) => {
        let url = url_json + "/actualizar-video/" + id;
        let body = {
            campo: state.campo,
            archivo: state.archivo,
            titulo: state.titulo,
            vista: state.vista
        }
        let request = {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }
        let response = await fetch(url, request);
        let res = await response.json();
        if (res.success) {
            Swal.fire(
              'Inserción exitosa!',
              'campo actualizado correctamente' ,
              'success'
            ).then(() => {
            //   window.location.reload();
            })
        } else {
            Swal.fire(
                'Error!',
                'No se pudo actualizar el campo, codigo: ' + res.code ,
                'error'
            ).then(() => {
                // window.location.reload();
            })
        }
        return res;

    },
    EliminarVideo: async (id) => {
        let url = url_json + "/eliminar-video/" + id;
        let request = {
            method:'DELETE',
            headers: {
                "Content-type":"application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let json = await response.json();
        if (json.success) {
            Swal.fire(
                'Eliminacion exitosa!',
                'Campo eliminado correctamente',
                'success'
            ).then(e => {
                if (e.isConfirmed || e.isDismissed) {
                    // console.log("entro", json);
                    // window.location.href = "/admin/contactos";
                }
            })
        } else {
            Swal.fire(
                'Error !',
                'No se pudo eliminar el campo',
                'error'
            )
        }
        return json;
    }
}

export default (VideosHelper);