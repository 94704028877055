import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import Downloader from "js-file-downloader";

import Loader from "../../Components/Loader/Loader";
import "./Calendario.css";

import img1 from "../../Images/escritorio/Calendario/1.png";
import movil1 from "../../Images/movil/Calendario/1.png";

import eventos from "./Eventos";
import calendario from "../../Docs/calendario.pdf";

const Calendario = () => {
  const [bandera, setBander] = useState(false);
  const [diasMes, setDiasMes] = useState([]);
  const semana = ["LUN", "MAR", "MIE", "JUE", "VIE", "SAB", "DOM"];
  const mesString = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const [mes, setMes] = useState(new Date().getMonth());
  const [anio, setAnio] = useState(new Date().getFullYear());
  const [eventosActuales, setEventosActuales] = useState([]);

  useEffect(() => {
    calcularCalendario(new Date().getMonth(), new Date().getFullYear());
  }, []);

  const proximoMes = () => {
    let numeroMes = mes + 1;
    let numeroAnio = anio;
    if (numeroMes === 12) {
      numeroMes = 0;
      numeroAnio = numeroAnio + 1;
      setAnio(numeroAnio);
    }
    setBander(false);
    setMes(numeroMes);
    calcularCalendario(numeroMes, numeroAnio);
  };

  const mesAnterior = () => {
    let numeroMes = mes - 1;
    let numeroAnio = anio;
    if (numeroMes < 0) {
      numeroMes = 11;
      numeroAnio = numeroAnio - 1;
      setAnio(numeroAnio);
    }
    setBander(false);
    setMes(numeroMes);
    calcularCalendario(numeroMes, numeroAnio);
  };

  const calcularCalendario = (mes, anio) => {
    let priemDia = new Date(anio, mes, 1);
    let ultimoDia = new Date(anio, mes + 1, 0);
    const mesArray = [];
    let obtenerDiaSemana = () => {
      if (priemDia.getDay() - 1 >= 0) {
        return priemDia.getDay() - 1;
      } else {
        return 6;
      }
    };
    let diaSemana = obtenerDiaSemana();
    for (let diaNulo = 0; diaNulo < diaSemana; diaNulo++) {
      if (diaNulo % 7 === 0) {
        //lunes
        mesArray.push({
          dia: false,
          class: "calendario_lunes calendario_vacio",
        });
      } else {
        //demas dias
        mesArray.push({ dia: false, class: "calendario_dia calendario_vacio" });
      }
    }
    for (let dia = 1; dia < ultimoDia.getDate() + 1; dia++) {
      let fecha = new Date(anio, mes, dia);
      let color = [];
      let busqueda = eventos.filter(
        (evento) =>
          evento.dia === fecha.getDate() &&
          evento.mes === fecha.getMonth() &&
          evento.anio === fecha.getFullYear()
      );
      busqueda.map((evento) => {
        color.push(evento.color);
      });
      let json;
      if (fecha.getDay() === 1) {
        //lunes
        json = {
          color: color,
          dia,
          class: "calendario_lunes",
        };
      } else {
        //demas dias
        json = {
          color: color,
          dia,
          class: "calendario_dia",
        };
      }
      mesArray.push(json);
    }
    setDiasMes(mesArray);
    setBander(true);
  };

  const download = () => {
    new Downloader({
      url: calendario,
    })
      .then(function () {
        // console.log("descargando");
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "Error al descargar archivo", "error");
      });
  };

  const verEventos = (dia) => {
    let busqueda = eventos.filter(
      (evento) =>
        evento.dia === dia && evento.mes === mes && evento.anio === anio
    );
    setEventosActuales(busqueda);
  };

  return (
    <Fragment>
      {window.screen.width > 550 ? (
        <img src={img1} style={{ width: "100%" }} />
      ) : (
        <img src={movil1} style={{ width: "100%" }} />
      )}

      <div className="container">
        <div className="center">
          <p
            className="titulo_1_nv calendario_movil_bajo"
            style={{ marginTop: "15px" }}
          >
            Calendario
          </p>
        </div>
        {!bandera ? (
          <Loader />
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div className="calendario">
              <div className="header_calendario" style={{ marginLeft: "5px" }}>
                {/* <div className='flecha izq' onClick={mesAnterior} /> */}
                <div
                  className="titulo_4_nv center_movil"
                  style={{ fontWeight: "bold" }}
                >
                  <p>
                    {mesString[mes]} {anio}
                  </p>
                </div>
                {/* <div className='flecha dec' onClick={proximoMes} /> */}
              </div>
              <div className="calendario_semanas">
                {semana.map((semana) => (
                  <div className="dia_semana">
                    <p>{semana}</p>
                  </div>
                ))}
              </div>
              <div className="calendario_dias">
                {diasMes.map((dia) =>
                  dia.dia ? (
                    dia.color.length > 0 ? (
                      <div
                        onClick={() => verEventos(dia.dia)}
                        className={"dia " + dia.class}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ margin: "0px" }}>{dia.dia}</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {dia.color.map((color) => (
                            <div
                              className="punto"
                              style={{ background: color, margin: "0px 1px" }}
                            />
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() => setEventosActuales([])}
                        className={"dia " + dia.class}
                      >
                        <p>{dia.dia}</p>
                      </div>
                    )
                  ) : (
                    <div className={"dia_vacio " + dia.class} />
                  )
                )}
              </div>
            </div>
            <div className="eventos">
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  margin: "0px 35px",
                }}
              >
                Eventos
              </p>
              {eventosActuales.length != 0 ? (
                eventosActuales.map((evento) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p style={{ margin: "0px 10px" }}>{evento.fecha}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          background: evento.color,
                        }}
                      />
                      <p style={{ margin: "0px 10px" }}>{evento.evento}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <p>No hay eventos en esta fecha</p>
                </div>
              )}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100px",
              justifyContent: "space-between",
            }}
          >
            <div className="flecha izq" onClick={mesAnterior} />
            <div className="flecha dec" onClick={proximoMes} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "50px",
          }}
        >
          <div
            className="boton_1_nv"
            onClick={download}
            style={{ cursor: "pointer" }}
          >
            <p>Descargar</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Calendario;
