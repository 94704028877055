import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";

import imagenVideos from "../../Images/imagenVideos.png";
import imagenVideos2 from "../../Images/imagenVideos2.png";
import imagenVideos3 from "../../Images/imagenVideos3.png";
import imagenVideos4 from "../../Images/imagenVideos4.png";
import imagenVideos5 from "../../Images/imagenVideos5.png";
import imagenVideos6 from "../../Images/imagenVideos6.png";

const Carrusel = () => {
  const [videos, setVideos] = useState([
    {
      imagen: imagenVideos,
      url: "https://www.youtube.com/embed/QyOlxwlFygE?si=5uDr5PBT9R9QnUCe",
      activo: false,
      nombre: "Zahira Rico",
      puesto: "Coordinadora de la Licenciatura",
    },
    {
      imagen: imagenVideos2,
      url: "https://www.youtube.com/embed/K9EebBkTK7A?si=9OIFjrtjeXGIptKv",
      activo: false,
      nombre: "Emiliano Robertson",
      puesto: "Coordinador del eje Estudios del Consumidor",
    },
    {
      imagen: imagenVideos3,
      url: "https://www.youtube.com/embed/UMP5Vjmg1yI?si=IOoeA2wRvZIIGad",
      activo: true,
      nombre: "Exael Ponce",
      puesto: "Coordinador del eje Consultoría en Creatividad Publicitaria",
    },
    {
      imagen: imagenVideos4,
      url: "https://www.youtube.com/embed/wkXZwcVgIEk?si=exsiOu8yNssDL8Bs",
      activo: false,
      nombre: "Mario Partida",
      puesto:
        "Coordinador del eje de Estrategias de Marketing en Redes Sociales ",
    },
    {
      imagen: imagenVideos5,
      url: "https://www.youtube.com/embed/VcJbad7MCHY?si=mS1TKb8UyKMZWZ-w",
      activo: false,
      nombre: "Mónica Blumen",
      puesto: "Coordinadora del eje de Producción Audiovisual Publicitaria ",
    },
    {
      imagen: imagenVideos6,
      url: "https://www.youtube.com/embed/lL4_aESuSSc?si=PjFBY2kBvaXTaA8a",
      activo: false,
      nombre: "Alex Zaragoza",
      puesto: "Coordinador del eje de Emprendimiento",
    },
  ]);

  const activarVideo = (index) => {
    let copiaVideos = [...videos];

    let indexVideoActivo = copiaVideos.findIndex((video) => video.activo);

    copiaVideos[index].activo = true;

    if (indexVideoActivo >= 0) {
      copiaVideos[indexVideoActivo].activo = false;
    }

    setVideos(copiaVideos);
  };

  return (
    <div className="container center">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        initialSlide={2}
        speed={600}
        preventClicks={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 80,
          depth: 350,
          modifier: 1,
          slideShadows: true,
        }}
        slideToClickedSlide={true}
        loop={false}
        pagination={false}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {videos.map((video, index) => (
          <SwiperSlide
            key={index}
            onClick={() => {
              activarVideo(index);
            }}
          >
            {video.activo ? (
              <div>
                <div
                  className="video-container"
                  style={{
                    backgroundImage:
                      "linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
                  }}
                >
                  <iframe
                    src={video.url}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div>
                  <p className="titulo_4_nv" style={{ marginBottom: "0" }}>
                    <b>{video.nombre}</b>
                  </p>
                  <p style={{ marginTop: "0" }}>{video.puesto}</p>
                </div>
              </div>
            ) : (
              <div
                className="container-video"
                style={{
                  backgroundImage:
                    "linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
                }}
              >
                <img src={video.imagen} alt="Video" />
                <div>
                  <i className="material-icons play-icon">play_arrow</i>
                </div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carrusel;
