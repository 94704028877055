var url_json = require('../location.json')

if (window.location.origin.includes('localhost')) {
  url_json = url_json.local
} else {
  url_json = url_json.production
}

const Sesiones = {
  obtenerUnaSesion: async (id) => {
    let url = url_json + '/sesiones/' + id
    let request = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    }

    let response = await fetch(url, request)
    let json = await response.json()
    return json
  }
}

export default Sesiones
