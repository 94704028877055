import React from "react";

const Reciente = ({ blog }) => {
  const licenciaturas = [
    "CINE DIGITAL",
    "MULTIMEDIA",
    "MARKETING DIGITAL",
    "ANIMACIÓN",
    "CULTURA GENERAL",
  ];
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  return (
    <a href={`/caav-blog/${blog.url}`}>
      <div
        style={{ display: "flex", alignItems: "center" }}
        className="row etiqueta-blog-chico"
      >
        <div className="col s6 m4 l6">
          <div className="contenedor-imagen-blog">
            <img src={blog.imagenExterna} alt={blog.titulo} />
          </div>
        </div>
        <div className="col s6 m8 l6">
          <p className="licenciatura-hastag">
            #{licenciaturas[blog.licenciatura]}
          </p>
          <p style={{ color: "#000000", fontWeight: "bold" }}>{blog.titulo}</p>
          <p className="fecha-blog-etiqueta">
            {new Date(blog.createdAt).getDate() +
              " de " +
              meses[new Date(blog.createdAt).getMonth()] +
              " " +
              new Date(blog.createdAt).getFullYear()}
          </p>
        </div>
      </div>
    </a>
  );
};

export default Reciente;
