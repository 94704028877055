import React, { useEffect, useState } from 'react'
import CryptoJS from 'react-native-crypto-js'
import Swal from 'sweetalert2'

import Loader from '../../Components/Loader/Loader'

import SesionesHelper from '../../Helpers/Sesiones'

import './Login.css'

import img1 from '../../Images/escritorio/Horarios/1.png'
import movil1 from '../../Images/movil/Horarios/1.png'
import icon1 from '../../Images/Icon/50.svg'

const Horario = () => {
  const [password, setPassword] = useState(0)
  const [sesiones, setSesiones] = useState('')

  useEffect(() => {
    obtenerSesion()
  }, [])

  const obtenerSesion = async () => {
    let res = await SesionesHelper.obtenerUnaSesion('0')
    if (res.success) {
      setSesiones(res.data[0].password)
    } else {
      Swal.fire(
        'Error!',
        'No se pudo obtener la información, codigo: ' + res.code,
        'error'
      ).then(() => {
        window.location.reload()
      })
    }
  }

  const leerDato = (e) => {
    e.preventDefault()
    setPassword(e.target.value)
  }

  const login = (e) => {
    e.preventDefault()
    // let bytes  = CryptoJS.AES.decrypt(props.usuario[0].password, 'Y2Fhdg==');
    // let originalText = bytes.toString(CryptoJS.enc.Utf8);

    if (sesiones === password) {
      redireccionar()
    } else {
      Swal.fire('Error', 'Password Incorrecto', 'error')
    }
  }

  const redireccionar = () => {
    let ciphertext = CryptoJS.AES.encrypt(
      'sesion$iniciada&Y29udHJhc2XDsWE=',
      'Y2Fhdg=='
    ).toString()

    sessionStorage._session = ciphertext
    sessionStorage._tipo = window.btoa(0)
    window.location.replace('/alumnos/pdf-caav')
  }

  return (
    <div>
      {window.screen.width > 550 ? (
        <img src={img1} style={{ width: '100%' }} />
      ) : (
        <img src={movil1} style={{ width: '100%' }} />
      )}

      <div className="contenedor" style={{ textAlign: 'center' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '-10px',
            marginTop: '10px'
          }}
        >
          <img src={icon1} className="icon_login" />
        </div>
        <p className="titulo_1_nv" style={{ margin: '5px 0px' }}>
          Horarios
        </p>
        <p>
          Para conocer tus horarios, ingresa tu <i>password</i>
        </p>
        {sesiones === '' ? (
          <Loader />
        ) : (
          <form onSubmit={login}>
            <div>
              <input
                placeholder="Ingresar password"
                onChange={leerDato}
                id="password"
                name="password"
                type="password"
                className="validate password"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                className="boton_1_nv"
                type="submit"
                style={{ margin: '20px 0% 50px', border: 'none' }}
              >
                <p>Ingresar</p>
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default Horario
