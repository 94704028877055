import React, { Fragment, useEffect, useState } from "react";
import Downloader from "js-file-downloader";
import Swal from "sweetalert2";

import folleto from "../../../Docs/folleto.pdf";

import "./Animacion.css";

import video from "../../../Images/escritorio/Licenciaturas/Animacion/video/video.m4v";
import imagenVideo from "../../../Images/escritorio/Licenciaturas/Animacion/video/1.png";

import logo_2 from "../../../Images/logos/logo_2.svg";
import img1 from "../../../Images/escritorio/Licenciaturas/Animacion/1.png";
import icono_1 from "../../../Images/Icon/3_1.png";
import icono_2 from "../../../Images/Icon/4_1.png";

import Loader from "../../../Components/Loader/Loader";
import licenciaturasHelpers from "../../../Helpers/Licenciaturas";

const Animacion = () => {
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [condiciones, setCondiciones] = useState(false);

  const gtag_report_conversion_poppop = (url) => {
    // proceso de admision
    var callback = function () {
      if (typeof url != "undefined") {
        var nuevaVentana = window.open();
        nuevaVentana.location.href = url;

        // window.location.replace(url)
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-1004461178/8ajUCJzJuawYEPq4-94D",
      event_callback: callback,
    });
    return false;
  };

  useEffect(() => {
    // Swal.fire({
    //   html:
    //     "<p class='texto_pop'><span class='titulo_4_nv titulo_bold'>Entra a la charla informativa</span></br>" +
    //     "de Animación. Miércoles 12 de marzo a las 5 pm.</p>",
    //   showCloseButton: true,
    //   showCancelButton: false,
    //   focusConfirm: false,
    //   confirmButtonText: '<i class="fa fa-whatsapp"></i> Pide aquí tu acceso',
    //   confirmButtonColor: "#C91CB8",
    // }).then((res) => {
    //   if (res.isConfirmed) {
    //     // redireccionamos
    //     // gtag_report_conversion_poppop(
    //     //   "https://api.whatsapp.com/send?phone=3322351431"
    //     // );
    //     var nuevaVentana = window.open();
    //     nuevaVentana.location.href =
    //       "https://api.whatsapp.com/send?phone=3322351431";
    //   }
    // });
    obtenerLicenciatura();
  }, []);

  const obtenerLicenciatura = async () => {
    let res = await licenciaturasHelpers.obtenerUnaLicenciatura(
      "6404b97e0bfddd714255ddee"
    );

    if (res.success) {
      setData(res.data);
      setFlag(true);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener la licenciatura, codigo: " + res.code,
        "error"
      );
    }
  };

  function createMarkup(materias) {
    return { __html: materias };
  }

  const download = () => {
    new Downloader({
      url: folleto,
    })
      .then(function () {
        // console.log("descargando");
      })
      .catch(function (error) {
        Swal.fire("Error", "Error al descargar archivo", "error");
      });
  };

  // seguimiento de google
  const gtag_report_conversion_descragar = () => {
    // descargar folleto
    var callback = function () {
      if (typeof folleto != "undefined") {
        window.open(folleto, "_blank");
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-1004461178/X1lcCMi9o5AYEPq4-94D",
      event_callback: callback,
    });
    return false;
  };

  const gtag_report_conversion = (url) => {
    // proceso de admision
    var callback = function () {
      if (typeof url != "undefined") {
        window.location.replace(url);
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-1004461178/l1PzCL-9q5AYEPq4-94D",
      event_callback: callback,
    });
    return false;
  };

  return (
    <Fragment>
      {condiciones && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="contenedor_pop_curso"
            onClick={() => {
              setCondiciones(false);
            }}
          ></div>
          <div className="pop_curso">
            <div
              className="pop_salir"
              onClick={() => {
                setCondiciones(false);
              }}
            />
            <p
              className="titulo_pop"
              style={{ color: "#b612c5", textAlign: "center", margin: "0" }}
            >
              ¿Qué validez tienen nuestras licenciaturas?
            </p>
            <p>
              Nuestra Universidad se encuentra incorporada a la Secretaría de
              Innovación, Ciencia y Tecnología de Jalisco (SICyT). En Jalisco,
              desde 2013, esta es la Secretaría que se encarga de dar validez a
              planes y programas de estudios de nivel superior, lo que en otros
              Estados hace la Secretaría de Educación. <br />
              <br />
              Nuestras licenciaturas cuentan con el Reconocimiento de Validez
              Oficial de Estudio RVOE, y Cedula Federal, los cuales puedes
              consultar en el siguiente Link:{" "}
              <a href="https://sicyt.jalisco.gob.mx/educacion-superior/directorio-de-instituciones-con-rvoe">
                https://sicyt.jalisco.gob.mx/educacion-superior/directorio-de-instituciones-con-rvoe
              </a>{" "}
              donde nos puedes buscar con el nombre de fundación de la
              Institución: Centro de Arte Audiovisual.
            </p>
          </div>
        </div>
      )}
      <video
        style={{ width: "100%" }}
        id="video"
        autoPlay
        muted
        loop
        poster={imagenVideo}
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className="container center" style={{ marginBottom: "20px" }}>
        <h1 className="encabezadosCaav" style={{ marginTop: "15px" }}>
          <span className="encabezadosMonserrat">Licenciatura en</span>
          <br />
          Animación
        </h1>
        <img src={logo_2} className="logo_yo_creo" alt="#yoCreo CAAV" />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="titulo_frase">
            Yo creo historias. <br />
            Yo creo en el movimiento.
            <br />
            Yo creo piezas de arte para tocar el alma.
          </p>
        </div>
        {!flag ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
            }}
          >
            <Loader />
          </div>
        ) : (
          <iframe
            className="altura_video_licenciatura"
            style={{ width: "100%" }}
            src={data.video}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        <div className="caja_5" style={{ background: "#F2F2F2" }}>
          <div className="texto_licenciatura_carrera">
            <p className="titulo_1_nv" style={{ margin: "0px" }}>
              La carrera
            </p>
            <p>
              Esta licenciatura está diseñada para prepararte en los diferentes
              campos y procesos de la animación clásica y digital, como stop
              motion, 2D y 3D.
            </p>
            <p>
              Al estudiarla, dominarás las habilidades técnicas y artísticas que
              te permitirán conectar con los espectadores, narrar historias
              extraordinarias y hacer uso de las tecnologías de producción
              presentes y futuras.
            </p>
            <p>
              Sabrás diseñar personajes, fondos y videojuegos; crear
              storyboards, esculturas, ilustraciones, marionetas, maquetas,
              comics, videojuegos, realidad aumentada, realidad virtual,
              cortometrajes, videoclips, películas, contenidos culturales y
              proyectos artísticos, para diferentes medios de comunicación.
            </p>
            <p>
              Aprenderás historia del cine, actuación, modelado, texturización,
              rigging, efectos visuales y gestión de proyectos.
            </p>
            <p>
              Vas a ser una persona experta en la creación de contenidos y
              desarrollo de proyectos de animación e ilustración para medios
              interactivos, redes sociales, cine y televisión.
            </p>
            <p>
              Esta carrera te dará la oportunidad de ser parte o líder de
              equipos calificados para realizar proyectos de animación, desde su
              etapa de conceptualización hasta la de distribución.
            </p>
          </div>
          <div className="texto_licenciatura_cine_perfil_ingreso">
            <p className="titulo_1_nv left-desktop" style={{ margin: "0px" }}>
              Perfil de ingreso
            </p>
            <div>
              <p style={{ fontWeight: "bold" }}>Intereses:</p>
              <div className="flex_movil_2">
                <div className="texto_lic_9 puntos_1_nv">
                  <ul>
                    <li>
                      Las historias, los relatos, los personajes, las ideas
                      creativas.
                    </li>
                    <li>El arte en general.</li>
                    <li>Los cómics, el animé y la ilustración.</li>
                    <li>Las nuevas tecnologías, la luz, el movimiento.</li>
                    <li>El appeal del diseño.</li>
                    <li>La fotografía, el cine, los videojuegos, la música.</li>
                  </ul>
                </div>
              </div>
              <p style={{ fontWeight: "bold" }}>Habilidades y destrezas:</p>
              <div className="puntos_1_nv contenedor_habilidades_a">
                <ul>
                  <li className="texto_lic_9">Facilidad para el dibujo.</li>
                  <li className="texto_lic_9">Imaginación activa.</li>
                  <li className="texto_lic_9">Capacidad narrativa.</li>
                  <li className="texto_lic_9">
                    Facilidad para la composición digital o manual.
                  </li>
                  <li className="texto_lic_9">Observación.</li>
                  <li className="texto_lic_9">
                    Gusto por el cine, medios audiovisuales y artes gráficas.
                  </li>
                  <li className="texto_lic_9">Curiosidad.</li>
                  <li className="texto_lic_9">Disciplina.</li>
                  <li className="texto_lic_9">
                    Personalidad creativa e innovadora.
                  </li>
                  <li className="texto_lic_9">Atención al detalle.</li>
                  <li className="texto_lic_9">Determinación y dedicación.</li>
                  <li className="texto_lic_9">
                    Manejo de herramientas tecnológicas.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container contenedor_chico" style={{ marginTop: "15px" }}>
        <div className="center">
          <p className="titulo_4_nv" style={{ fontWeight: "bold" }}>
            Tu campo de trabajo
          </p>
          <p>
            En un mundo que cada vez consume y requiere más y más medios
            audiovisuales para el entretenimiento y la vida cotidiana, tu campo
            de trabajo se irá haciendo cada vez más vasto. Por ejemplo:
          </p>
        </div>
        <div style={{ marginLeft: "30px" }}>
          <ul className="puntos">
            <li>Televisoras.</li>
            <li>Dirección de arte.</li>
            <li>Arte 3D generalista.</li>
            <li>Producción ejecutiva.</li>
            <li>Desarrollo de apps y redes sociales.</li>
            <li>Casas de producción cinematográfica.</li>
            <li>Empresas de efectos especiales digitales.</li>
            <li>Ilustración para audiovisuales e impresos.</li>
            <li>Casas de animación:</li>
            <ul className="puntos" style={{ paddingLeft: "15px" }}>
              <li>Animación de motion graphics.</li>
              <li>Animación de personajes en 2D y 3D.</li>
              <li>Animación de efectos visuales en 2D y 3D.</li>
              <li>
                Empresas desarrolladoras de videojuegos y medios interactivos.
              </li>
            </ul>
            <li>
              Dirección de proyectos multidisciplinarios de entretenimiento o
              comunicación.
            </li>
          </ul>
        </div>
        <p className="center">
          Y vas a tener la visión y capacidad para desarrollar tu propia
          empresa: por ejemplo, una casa de producción cinematográfica, de
          efectos especiales, de casting, de locaciones, de sonido o de
          postproducción.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <a
          onClick={() => {
            gtag_report_conversion("https://caav.mx/proceso-admision");
          }}
          href="/proceso-admision"
        >
          <div className="boton_licenciatura_1" style={{ marginTop: "0px" }}>
            Conoce el proceso de admisión
          </div>
        </a>
      </div>

      {!flag ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div
          className="center contenedor_d3"
          style={{ background: "#B612C5", color: "#fff" }}
        >
          <p
            className="titulo_5_nv"
            style={{ margin: "0px", fontWeight: "bold" }}
          >
            Inicio de clases:
          </p>
          <p className="titulo_fecha_nv" style={{ margin: "0px" }}>
            {data.inicio}
          </p>
          <p
            className="titulo_5_nv"
            style={{ fontWeight: "bold", marginBottom: "0px" }}
          >
            Próximo inicio
            <br />
            {data.proximo_inicio[0]}
            <br />
            {data.proximo_inicio[1]}
          </p>
        </div>
      )}

      <div
        className="contenedor_2_d3 flex_padre_cine"
        style={{ background: "#E4E4E4" }}
      >
        <div
          className="flex_1"
          style={{
            display: "flex",
            // justifyContent:"center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <img
            src={icono_1}
            alt=""
            className="imagen_lic"
            style={{ fontSize: "14px" }}
          />
          <p className="titulo_4_nv" style={{ marginBottom: "0px" }}>
            ¿Qué validez tienen <br />
            nuestras licenciaturas?
          </p>
          <p style={{ margin: "0" }}>ESLI14201830</p>
          <button
            style={{
              color: "#000",
              border: "none",
              background: "transparent",
              fontWeight: "bold",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setCondiciones(true);
            }}
          >
            Ver detalles.
          </button>
        </div>
        <div
          className="flex_1"
          style={{
            display: "flex",
            // justifyContent:"center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <img
            src={icono_2}
            alt=""
            className="imagen_lic"
            style={{ fontSize: "14px" }}
          />
          <p className="titulo_4_nv" style={{ marginBottom: "0px" }}>
            Horario
          </p>
          <p style={{ marginTop: "0px" }}>
            De lunes a viernes, de 8:00 am a 3:00 pm. <br />
            Pueden programarse algunas sesiones el sábado.
          </p>
        </div>
      </div>

      <div
        className="row"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "stretch",
          marginBottom: "0px",
        }}
      >
        <div className="col s12 m12 l5" style={{ padding: "0px" }}>
          <div style={{ height: "100%" }}>
            <img
              src={img1}
              className="imagen_licenciatura"
              alt="Licenciatura en Animación Digital"
            />
          </div>
        </div>
        <div className="col s12 m12 l7" style={{ background: "#fff" }}>
          <p
            className="encabezadosCaav contenedor_lic_plan"
            style={{ marginTop: "20px", marginBottom: "0px" }}
          >
            Esto es lo que aprenderás <br />
            <span className="encabezadosMonserrat">
              Plan de estudios, en nueve cuatrimestres
            </span>
          </p>
          {!flag ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100%",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div className="flex_2_d3 contenedor_lic_plan">
              <div className="hijo_1_d3">
                <p className="numero_d3_a" style={{ marginBottom: "-10px" }}>
                  1.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[0].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_a" style={{ marginBottom: "-10px" }}>
                  2.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[1].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_a" style={{ marginBottom: "-10px" }}>
                  3.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[2].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_a" style={{ marginBottom: "-10px" }}>
                  4.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[3].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_a" style={{ marginBottom: "-10px" }}>
                  5.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[4].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_a" style={{ marginBottom: "-10px" }}>
                  6.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[5].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_a" style={{ marginBottom: "-10px" }}>
                  7.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[6].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_a" style={{ marginBottom: "-10px" }}>
                  8.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[7].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_a" style={{ marginBottom: "-10px" }}>
                  9.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[8].data)}
                  className="texto_lic_8"
                />
              </div>
              <div
                className="col s12"
                style={{ marginTop: "30px", padding: "0px" }}
              >
                <button
                  onClick={gtag_report_conversion_descragar}
                  className="boton_licenciatura_1"
                >
                  Descargar folleto
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Animacion;
