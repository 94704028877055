import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";

import "./Blogs.css";

import blogsHelper from "../../Helpers/Blogs";
import etiquetasBlogs from "../../Helpers/EtiquetasBlogs";

import Loader from "../../Components/Loader/Loader";
import Principal from "../../Components/Blogs/Principal";
import Reciente from "../../Components/Blogs/Reciente";

const Blogs = () => {
  const [flag, setFlag] = useState(false);
  const [flagBlog, setFlagBlog] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [blogsMostrar, setBlogsMostrar] = useState([]);
  const [blogsRecientes, setBlogsRecientes] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [paginadoIndex, setPaginadoIndex] = useState(1);
  const [paginado, setPaginado] = useState([]);
  const limitePaginado = 6;

  useEffect(() => {
    obtenerBlogs();
  }, []);

  const obtenerBlogs = async () => {
    let res = await blogsHelper.obtenerBlogs();

    if (res.success) {
      // orden de blogs por fechas y limitacion a 4
      let blogsOrden = [...res.data];

      blogsOrden.sort((a, b) => {
        const fechaA = new Date(a.createdAt);
        const fechaB = new Date(b.createdAt);
        if (fechaA > fechaB) {
          return -1;
        }
        if (fechaA < fechaB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      let limiteBlogs = blogsOrden.slice(0, 4);
      let blogsPaginado = blogsOrden.slice(0, limitePaginado);

      setBlogsRecientes(limiteBlogs);
      setBlogs(blogsOrden);
      setBlogsMostrar(blogsOrden);
      setPaginado(blogsPaginado);

      obtenerEtiquetas();

      // evaluamos si tiene redireccion por filtro de etiqueta
      if (sessionStorage.getItem("__fl-etq-bg-cv__")) {
        filtroEtiqueta(sessionStorage.getItem("__fl-etq-bg-cv__"), res.data);
        sessionStorage.removeItem("__fl-etq-bg-cv__");
      }

      // evaluamos si tiene redireccion por filtro de tema
      if (sessionStorage.getItem("__fl-tem-bg-cv__")) {
        filtroTema(sessionStorage.getItem("__fl-tem-bg-cv__"), res.data);
        sessionStorage.removeItem("__fl-tem-bg-cv__");
      }
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener los blogs, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerEtiquetas = async () => {
    let res = await etiquetasBlogs.obtenerEtiquetas();

    if (res.success) {
      setEtiquetas(res.data);
      setFlag(true);
      setFlagBlog(true);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener las etiquetas, codigo: " + res.code,
        "error"
      );
    }
  };

  const filtroTema = (tema, blogParam = []) => {
    const copiaBlog = blogParam.length > 0 ? [...blogParam] : [...blogs];

    let filtro = copiaBlog.filter(
      (blog) => blog.licenciatura === parseInt(tema)
    );

    let blogsPaginado = filtro.slice(0, 1 * limitePaginado);

    setBlogsMostrar(filtro);
    setPaginado(blogsPaginado);
    setPaginadoIndex(1);
  };

  const filtroEtiqueta = (etiqueta, blogParam = []) => {
    const copiaBlog = blogParam.length > 0 ? [...blogParam] : [...blogs];

    let filtro = copiaBlog.filter((blog) => blog.etiquetas.includes(etiqueta));

    let blogsPaginado = filtro.slice(0, 1 * limitePaginado);

    setBlogsMostrar(filtro);
    setPaginado(blogsPaginado);
    setPaginadoIndex(1);
  };

  const mostrarMas = () => {
    if (paginadoIndex * limitePaginado > blogsMostrar.length) {
      return Swal.fire(
        "Upps!",
        "Son todos los blogs que tenemos :D",
        "warning"
      );
    }
    let copiaIndex = paginadoIndex + 1;

    let blogsPaginado = blogsMostrar.slice(0, paginadoIndex * limitePaginado);
    setPaginado(blogsPaginado);
    setPaginadoIndex(copiaIndex);
  };

  const buscador = (e) => {
    setFlagBlog(false);
    const copiaBlog = [...blogs];
    let letras = e.target.value;

    setTimeout(() => {
      if (letras === "") {
        let blogsPaginado = copiaBlog.slice(0, 1 * limitePaginado);

        setBlogsMostrar(copiaBlog);
        setPaginado(blogsPaginado);
        setPaginadoIndex(1);
        setFlagBlog(true);
      } else {
        const blogFilter = copiaBlog.filter((blog) => {
          // Eliminar acentos tanto de `blog.titulo` como de `letras`
          const tituloSinAcentos = blog.titulo
            .normalize("NFD") // Descompone caracteres acentuados
            .replace(/[\u0300-\u036f]/g, ""); // Elimina los diacríticos (acentos)

          const letrasSinAcentos = letras
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          // Comparar los textos sin acentos
          return (
            tituloSinAcentos
              .toUpperCase()
              .indexOf(letrasSinAcentos.toUpperCase()) >= 0
          );
        });

        let blogsPaginado = blogFilter.slice(0, 1 * limitePaginado);

        setBlogsMostrar(blogFilter);
        setPaginado(blogsPaginado);
        setPaginadoIndex(1);
        setFlagBlog(true);
      }
    }, 1000);
  };

  return (
    <div className="row container">
      <div className="col s12 m12 l4 xl4" style={{ paddingRight: "50px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            margin: "40px 0 60px 0",
          }}
        >
          <div className="busqueda_cursos">
            <input
              placeholder="Buscar por titulo"
              id="busqueda_titulo"
              name="busqueda_titulo"
              type="text"
              className="validate formulario"
              onChange={buscador}
            />
            <label for="busqueda_titulo"></label>
          </div>
        </div>
        <p
          className="titulo_4_nv"
          style={{ marginBottom: "0px", color: "#00496C" }}
        >
          Buscar por temas
        </p>
        <div className="row">
          <div className="col s6 boton-licenciatura-blog">
            <button
              onClick={() => {
                filtroTema(0);
              }}
            >
              CINE DIGITAL
            </button>
          </div>
          <div className="col s6 boton-licenciatura-blog">
            <button
              onClick={() => {
                filtroTema(1);
              }}
            >
              MULTIMEDIA
            </button>
          </div>
          <div className="col s6 boton-licenciatura-blog">
            <button
              onClick={() => {
                filtroTema(2);
              }}
            >
              MARKETING DIGITAL
            </button>
          </div>
          <div className="col s6 boton-licenciatura-blog">
            <button
              onClick={() => {
                filtroTema(3);
              }}
            >
              ANIMACIÓN
            </button>
          </div>
          <div className="col s6 boton-licenciatura-blog">
            <button
              onClick={() => {
                filtroTema(4);
              }}
            >
              CULTURA GENERAL
            </button>
          </div>
        </div>
        {!flag ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "7px" }}>
            {etiquetas.map((etiqueta) => (
              <button
                onClick={() => {
                  filtroEtiqueta(etiqueta._id);
                }}
                className="boton-etiqueta-blog"
              >
                {etiqueta.nombre}
              </button>
            ))}
          </div>
        )}

        <div>
          <p
            className="titulo_4_nv"
            style={{ margin: "30px 0 0 0", color: "#00496C" }}
          >
            Artículos recientes
          </p>
          {!flag ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Loader />
            </div>
          ) : (
            blogsRecientes.map((blog) => <Reciente blog={blog} />)
          )}
        </div>
      </div>
      <div className="col s12 m12 l8 xl8">
        <div style={{ textAlign: "center" }}>
          <p className="titulo_1_nv">
            Explora los temas <br />
            que más te interesan
          </p>
          <p className="titulo_4_nv" style={{ color: "#00496C" }}>
            Todos nuestros artículos
          </p>
        </div>

        {!flagBlog ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <Fragment>
            <div
              className="row"
              style={{ display: "flex", flexWrap: "wrap", gap: "15px 0" }}
            >
              {paginado.map((blog) => (
                <Principal blog={blog} />
              ))}
            </div>
            <button className="boton_2_nv" onClick={mostrarMas}>
              Ver más
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Blogs;
