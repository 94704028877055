import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import { Fragment } from "react";

var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
  url_json = url_json.local;
} else {
  url_json = url_json.production;
}

const Baners = {
  obtenerBaners: async () => {
    let url = url_json + "/baners";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerBanersOferta: async () => {
    let url = url_json + "/baners-ofertas";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerBanersRegulares: async () => {
    let url = url_json + "/baners-regulares";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerConfiguracionBaners: async () => {
    let url = url_json + "/baners-configuracion";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerUnaBaner: async (id) => {
    let url = url_json + "/baner/" + id;
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
};

export default Baners;
