import React from "react";
import Swal from "sweetalert2";

import { ScrollingCarousel, Carousel } from "@trendyol-js/react-carousel";

import icon64 from "../../../Images/Icon/64.svg";
import Arrow from "../../../Images/arrow";

const CarruselPasos = () => {
  const informacion = () => {
    Swal.fire({
      html:
        "<p className='texto_pop'>" +
        "<span className='titulo_4_nv titulo_bold'>" +
        "Éstos son los documentos <br/>" +
        "que debes presentar:" +
        "</span><br/><br/>" +
        "<b>Acta de nacimiento:</b><br/>" +
        "original y copia<br/>" +
        "(vigencia de no más de tres meses)." +
        "<br/><br/>" +
        "<b>Certificado de bachillerato:</b><br/>" +
        "original y tres copias." +
        "<br/><br/>" +
        "<b>CURP</b>" +
        "<br/><br/>" +
        "<b>Una fotografía:</b><br/>" +
        "tamaño infantil." +
        "<br/><br/>" +
        "<b>" +
        "Constancia de validación<br/>" +
        "de certificado de bachillerato membretada:" +
        "</b><br/>" +
        "oficio en hoja membreteada de la preparatoria, <br/>" +
        "donde se indique tu nombre completo, <br/>" +
        "folio del certificado, y fecha de expedición <br/>" +
        "del certificado, firmado y sellado por el director <br/>" +
        "o coordinador de control escolar de tu preparatoria." +
        "</p>",
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
    });
  };

  return window.innerWidth > 550 ? (
    <Carousel
      show={window.innerWidth > 550 ? window.innerWidth / 330 : 2.1}
      slide={1}
      swiping={false}
      infinite={false}
      rightArrow={
        <button className="btn-izquierda">
          <Arrow width={"30px"} />
        </button>
      }
      leftArrow={
        <button className="btn-derecha">
          <Arrow width={"30px"} rotate={"rotate(180deg)"} />
        </button>
      }
    >
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#5BBF3F" }}
          >
            1 Contacta <br /> a una asesora
          </p>
          <p>
            Para que te guíe <br className="invisible_escritorio" />
            paso a paso.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#5BBF3F" }}
          href="https://api.whatsapp.com/send?phone=3322351431&text=Hola,%20buen%20d%C3%ADa.%20%F0%9F%98%81%20Me%20gustar%C3%ADa%20informaci%C3%B3n%20para%20inscribirme%20a%20una%20Licenciatura%20CAAV."
          target="_blanck"
        >
          Da clic aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#B52C93" }}
          >
            2 Evaluación <br />
            de perfil
          </p>
          <p>
            Llena en línea para conocer <br />
            tu perfil creativo <br />
            (no es un examen).
            <br />
            <br />
            <b style={{ color: "#B52C93" }}>Cuesta $500 MXN.</b> <br />
            Envía comprobante <br className="invisible_escritorio" />
            de tu pago.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#B52C93" }}
          href="mailto:lupita.rocha@caav.mx"
          target="_blanck"
        >
          Aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#02B5C1" }}
          >
            3 Haz tu <br />
            pago inicial
          </p>
          <p>
            Inscripción y <br />
            primera colegiatura.
          </p>
        </div>
        <div>
          <a
            className="boton-rein-nv"
            style={{ background: "#02B5C1" }}
            href="#precios"
          >
            Ver precios
          </a>
          <a
            className="boton-rein-nv"
            style={{ background: "#02B5C1", marginTop: "10px" }}
            href="#banco"
          >
            Dónde pagar
          </a>
        </div>
      </div>
      <div className="card-inscripcion">
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#F49A3F" }}
        >
          4 Llena tu <br className="invisible_escritorio" />
          formulario <br />
          de inscripción
        </p>
        <a
          className="boton-rein-nv"
          style={{ background: "#F49A3F" }}
          href="#formulario"
        >
          Aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#8A6AAC" }}
          >
            5 Recibe correo <br />
            de confirmación
          </p>
          <p>
            En 48 horas o menos, <br />
            te escribiremos <br />
            y te daremos fecha y hora <br />
            de tu charla de bienvenida.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#8A6AAC", lineHeight: "14px" }}
          href="mailto:tesoreria@caav.mx"
          target="_blanck"
        >
          Si no lo recibes, <br className="invisible_escritorio" />
          escribe aquí
        </a>
      </div>
      <div className="card-inscripcion" style={{ background: "#BA2049" }}>
        <img src={icon64} alt="CAAV" />
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#ffffff" }}
        >
          Sólo hasta que tengas la confirmación estarás inscrito
          <br className="invisible_escritorio" /> o inscrita.
        </p>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#F9027A" }}
          >
            6 Entrega pronto <br />
            tus papeles
          </p>
          <p>
            En la oficina <br />
            de Control Escolar.
          </p>
        </div>
        <button
          className="boton-rein-nv"
          style={{ background: "#F9027A" }}
          onClick={informacion}
        >
          Éstos son los papeles
        </button>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#5BBF3F" }}
          >
            7 Entra <br />
            a tu charla <br />
            de bienvenida
          </p>
          <p>
            para que conozcas <br />
            a tu grupo y a la directora.
            <br /> <br />
            Es en línea, con el link <br />
            que te llegará en tu correo <br />
            de confirmación.
          </p>
        </div>
      </div>
      <div style={{ width: "20%" }}></div>
    </Carousel>
  ) : (
    <ScrollingCarousel infinite={false}>
      <div className="card-inscripcion" style={{ marginLeft: "5%" }}>
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#5BBF3F" }}
          >
            1 Contacta <br /> a una asesora
          </p>
          <p>
            Para que te guíe <br className="invisible_escritorio" />
            paso a paso.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#5BBF3F" }}
          href="https://api.whatsapp.com/send?phone=3322351431&text=Hola,%20buen%20d%C3%ADa.%20%F0%9F%98%81%20Me%20gustar%C3%ADa%20informaci%C3%B3n%20para%20inscribirme%20a%20una%20Licenciatura%20CAAV."
          target="_blanck"
        >
          Da clic aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#B52C93" }}
          >
            2 valuación <br />
            de perfil
          </p>
          <p>
            Llena en línea para conocer <br />
            tu perfil creativo <br />
            (no es un examen).
            <br />
            <br />
            <b style={{ color: "#B52C93" }}>Cuesta $500 MXN.</b> <br />
            Envía comprobante <br className="invisible_escritorio" />
            de tu pago.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#B52C93" }}
          href="mailto:lupita.rocha@caav.mx"
          target="_blanck"
        >
          Aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#02B5C1" }}
          >
            3 Haz tu <br />
            pago inicial
          </p>
          <p>
            Inscripción y <br />
            primera colegiatura.
          </p>
        </div>
        <div>
          <a
            className="boton-rein-nv"
            style={{ background: "#02B5C1" }}
            href="#precios"
          >
            Ver precios
          </a>
          <a
            className="boton-rein-nv"
            style={{ background: "#02B5C1", marginTop: "10px" }}
            href="#banco"
          >
            Dónde pagar
          </a>
        </div>
      </div>
      <div className="card-inscripcion">
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#F49A3F" }}
        >
          4 Llena tu <br className="invisible_escritorio" />
          formulario <br />
          de inscripción
        </p>
        <a
          className="boton-rein-nv"
          style={{ background: "#F49A3F" }}
          href="#formulario"
        >
          Aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#8A6AAC" }}
          >
            5 Recibe correo <br />
            de confirmación
          </p>
          <p>
            En 48 horas o menos, <br />
            te escribiremos <br />
            y te daremos fecha y hora <br />
            de tu charla de bienvenida.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#8A6AAC", lineHeight: "14px" }}
          href="mailto:tesoreria@caav.mx"
          target="_blanck"
        >
          Si no lo recibes, <br className="invisible_escritorio" />
          escribe aquí
        </a>
      </div>
      <div className="card-inscripcion" style={{ background: "#BA2049" }}>
        <img src={icon64} alt="CAAV" />
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#ffffff" }}
        >
          Sólo hasta que tengas la confirmación estarás inscrito
          <br className="invisible_escritorio" /> o inscrita.
        </p>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#F9027A" }}
          >
            6 Entrega pronto <br />
            tus papeles
          </p>
          <p>
            En la oficina <br />
            de Control Escolar.
          </p>
        </div>
        <button
          className="boton-rein-nv"
          style={{ background: "#F9027A" }}
          onClick={informacion}
        >
          Éstos son los papeles
        </button>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#5BBF3F" }}
          >
            7 Entra <br />
            a tu charla <br />
            de bienvenida
          </p>
          <p>
            para que conozcas <br />
            a tu grupo y a la directora.
            <br /> <br />
            Es en línea, con el link <br />
            que te llegará en tu correo <br />
            de confirmación.
          </p>
        </div>
      </div>
      <div style={{ width: "20%" }}></div>
    </ScrollingCarousel>
  );
};

export default CarruselPasos;
