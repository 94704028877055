import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import M from "materialize-css";

import Etiqueta from "../../Components/Cursos/Etiqueta";
import Loader from "../../Components/Loader/Loader";

import BanersHelper from "../../Helpers/Baners";
import CursosHelper from "../../Helpers/Cursos";

// formato
import Curso from "../../Images/icon_js/Curso";
import Taller from "../../Images/icon_js/Taller";
import Diplomado from "../../Images/icon_js/Diplomado";
// modalidad
import Presencial from "../../Images/icon_js/Presencial";
import Videorreunion from "../../Images/icon_js/Videorreunion";
import Hibrido from "../../Images/icon_js/Hibrido";
import Google from "../../Images/icon_js/Google";
import Info from "../../Images/icon_js/Info";

const CursosArea = () => {
  let { areaTexto } = useParams("area");
  const [titulo, setTitulo] = useState("Cargando");

  const formatoEtiquetas = ["", "Taller", "Curso", "Diplomado"];
  const modalidadEtiquetas = [
    "",
    "Presencial",
    "Híbrido",
    "Videorreunion",
    "Classroom",
  ];

  const [area, setArea] = useState(1);
  const [data, setData] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [banderaBuscador, setBanderaBuscador] = useState(true);
  const [banderaCursos, setBanderaCursos] = useState(true);
  const [flag, setFlag] = useState(false);
  const [cursoSeleccionado, setCursoSeleccion] = useState([]);
  const [indexCurso, setIndexCurso] = useState(0);
  const [formato, setFormato] = useState(0);
  const [modalidad, setModalidad] = useState(0);

  // informacion baner
  const [color, setColor] = useState("");

  useEffect(() => {
    let areaNumero;

    if (areaTexto === "animacion-anime-y-comic") {
      areaNumero = "1";
      setTitulo("Animación, Animé y Cómic");
    } else if (areaTexto === "audio-y-voz") {
      areaNumero = "2";
      setTitulo("Audio y Voz");
    } else if (areaTexto === "cine-y-video") {
      areaNumero = "3";
      setTitulo("Cine y Video");
    } else if (areaTexto === "artes-plásticas") {
      areaNumero = "4";
      setTitulo("Artes plásticas");
    } else if (areaTexto === "diseño") {
      areaNumero = "5";
      setTitulo("Diseño");
    } else if (areaTexto === "emprendimiento") {
      areaNumero = "6";
      setTitulo("Emprendimiento");
    } else if (areaTexto === "escritura") {
      areaNumero = "7";
      setTitulo("Escritura");
    } else if (areaTexto === "fotografia") {
      areaNumero = "8";
      setTitulo("Fotografía");
    } else if (areaTexto === "teoria-del-arte") {
      areaNumero = "9";
      setTitulo("Teoría del arte");
    } else if (areaTexto === "marketing-y-la-web") {
      areaNumero = "10";
      setTitulo("Marketing y la Web");
    } else if (areaTexto === "multimedia-y-videojuegos") {
      areaNumero = "11";
      setTitulo("Multimedia y Videojuegos");
    } else if (areaTexto === "suite-de-adobe") {
      areaNumero = "12";
      setTitulo("Suite de Adobe");
    } else if (areaTexto === "artes-escenicas") {
      areaNumero = "13";
      setTitulo("Artes escénicas");
    } else {
      setTitulo("Error...");
    }

    sessionStorage.setItem("area", areaNumero);
    setArea(areaNumero);
    obtenerConfiguracionBaner(areaNumero);
  }, []);

  const obtenerConfiguracionBaner = async (areaNumero) => {
    // TODO: esta consulta debe de ser en un context para usarlo en multiples vistas y no hacer la consulta doble :v
    let res = await BanersHelper.obtenerConfiguracionBaners();

    if (res.success) {
      setColor(res.data.color);
      obtenerCursos(areaNumero);
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener la configuracion de los baners, codigo: " +
          res.code,
        "error"
      );
    }
  };

  const obtenerCursos = async (areaNumero) => {
    let res = await CursosHelper.obtenerUnCursoArea(areaNumero);

    if (res.success) {
      let arrayTemporal = [];
      let arrayCursos = [];
      let indexCurso = 0;

      let ordenado = res.data.sort((x, y) => {
        var firstDate = new Date(x.fechas[0].fecha_inicio);
        var secondDate = new Date(y.fechas[0].fecha_inicio);

        // Evaluar el campo estado_relampago.fecha_fin
        if (x.estado_relampago && x.estado_relampago.fecha_fin) {
          var currentDate = new Date();
          var xFechaFin = new Date(x.estado_relampago.fecha_fin);

          // Comprobar si x.estado_relampago.fecha_fin no ha pasado
          if (xFechaFin > currentDate) {
            // x.estado_relampago.fecha_fin no ha pasado, colocar x al comienzo
            return -1;
          }
        }

        if (firstDate < secondDate) return -1;
        if (firstDate > secondDate) return 1;
        return 0;
      });

      ordenado.map((curso, index, array) => {
        arrayTemporal.push(curso);
        indexCurso = indexCurso + 1;
        if (indexCurso % 8 === 0 && indexCurso !== 0) {
          arrayCursos.push(arrayTemporal);
          arrayTemporal = [];
          indexCurso = 0;
        } else if (index + 1 === array.length) {
          arrayCursos.push(arrayTemporal);
          arrayTemporal = [];
        }
      });

      setCursos(arrayCursos);
      setCursoSeleccion(arrayCursos[0]);
      setData(res.data);
      setFlag(true);

      let modalidadStorage = sessionStorage.getItem("modalidad");
      let formatoStorage = sessionStorage.getItem("formato");
      if (modalidadStorage) {
        return seleccionarModalidad(modalidadStorage, res.data);
      }
      if (formatoStorage) {
        return seleccionarFormato(formatoStorage, res.data);
      }
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener el directorio, codigo: " + res.code,
        "error"
      );
    }

    let elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, {
      html:
        "<p>Vía Google Classroom.</p>" +
        "<p>El docente sube videos, audios, <br/>presentaciones y enlaces y asigna tareas.</p>" +
        "<p>Tú consumes el contenido cuando y donde <br/>quieras, dentro de la duración del curso.</p>" +
        "<p>Ahí subes tus dudas y tus tareas. <br/>Recibes respuesta en 24 horas.</p>" +
        "<p>Tu docente y tu acuerdan días fijos para <br/>hablar por chat.</p>" +
        "<p>Terminas en el tiempo establecido.</p>",
    });
  };

  const seleccionarFormato = (formato, dataParams = []) => {
    let copiaData;
    if (dataParams.length !== 0) {
      copiaData = [...dataParams];
    } else {
      copiaData = [...data];
    }

    setFormato(parseInt(formato));
    sessionStorage.setItem("formato", formato);
    setBanderaCursos(false);
    setTimeout(() => {
      let arrayTemporal = [];
      let cursosFiltrados = [];
      let indexCurso = 0;
      copiaData.map((curso, index, array) => {
        let filtro = curso.formato.includes(formato);

        if (filtro) {
          arrayTemporal.push(curso);
          indexCurso = indexCurso + 1;
          if (indexCurso % 8 === 0 && indexCurso !== 0) {
            cursosFiltrados.push(arrayTemporal);
            arrayTemporal = [];
            indexCurso = 0;
          }
        }
        if (index + 1 === array.length && arrayTemporal.length != 0) {
          cursosFiltrados.push(arrayTemporal);
          arrayTemporal = [];
        }
      });
      setCursos(cursosFiltrados);
      setCursoSeleccion(cursosFiltrados[0]);
      setBanderaCursos(true);
      window.location.replace("#cursos");
    }, 1000);
  };

  const seleccionarModalidad = (modalidad, dataParams = []) => {
    let copiaData;
    if (dataParams.length !== 0) {
      copiaData = [...dataParams];
    } else {
      copiaData = [...data];
    }

    setModalidad(parseInt(modalidad));
    sessionStorage.setItem("modalidad", modalidad);
    setBanderaCursos(false);

    setTimeout(() => {
      let arrayTemporal = [];
      let cursosFiltrados = [];
      let indexCurso = 0;

      copiaData.map((curso, index, array) => {
        let filtro = curso.modalidad.includes(modalidad);

        if (filtro) {
          arrayTemporal.push(curso);
          indexCurso = indexCurso + 1;
          if (indexCurso % 8 === 0 && indexCurso !== 0) {
            cursosFiltrados.push(arrayTemporal);
            arrayTemporal = [];
            indexCurso = 0;
          }
        }
        if (index + 1 === array.length && arrayTemporal.length != 0) {
          cursosFiltrados.push(arrayTemporal);
          arrayTemporal = [];
        }
      });

      setCursos(cursosFiltrados);
      setCursoSeleccion(cursosFiltrados[0]);
      setBanderaCursos(true);
      window.location.replace("#cursos");
    }, 1000);
  };

  const mostrarMas = (clic) => {
    if (cursos.length === indexCurso + 1 && !cursos[indexCurso + 1]) {
      Swal.fire("Se acabaron", "Son todos los cursos del CAAV", "success");
    } else {
      let masCursos = cursoSeleccionado;
      let index = indexCurso + 1;
      cursos[index].map((curso) => {
        masCursos.push(curso);
      });
      setCursoSeleccion(masCursos);
      setIndexCurso(index);
    }
  };

  const busqueda = (e) => {
    setBanderaBuscador(false);
    let letras = e.target.value;
    let cursoFiltro = [...data];
    setTimeout(() => {
      let arrayTemporal = [];
      let arrayCursos = [];
      let indexCurso = 0;
      if (letras === "") {
        data.map((curso, index, array) => {
          arrayTemporal.push(curso);
          indexCurso = indexCurso + 1;
          if (indexCurso % 8 === 0 && indexCurso !== 0) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
            indexCurso = 0;
          } else if (index + 1 === array.length) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
          }
        });
        setCursos(arrayCursos);
        setCursoSeleccion(arrayCursos[0]);
        setBanderaBuscador(true);
      } else {
        cursoFiltro.filter((curso, index, array) => {
          // Eliminar acentos del nombre del curso y de la búsqueda
          const nombreSinAcentos = curso.nombre
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          const letrasSinAcentos = letras
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          // Realizar la búsqueda ignorando acentos
          let busqueda = nombreSinAcentos
            .toUpperCase()
            .indexOf(letrasSinAcentos.toUpperCase());

          if (busqueda >= 0) {
            indexCurso = indexCurso + 1;
            arrayTemporal.push(curso);
          }

          // Agrupar los resultados en bloques de 8 elementos
          if (indexCurso % 8 === 0 && indexCurso !== 0) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
            indexCurso = 0;
          } else if (index + 1 === array.length) {
            // Manejar los elementos restantes al final del array
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
          }
        });

        setCursos(arrayCursos);
        setCursoSeleccion(arrayCursos[0]);
        setBanderaBuscador(true);
      }
    }, 1000);
  };

  const enter = (e) => {
    let tecla = document.all ? e.keyCode : e.which;
    if (tecla == 13) {
      window.location.replace("#cursos");
    }
  };

  const verInfo = () => {
    let elems = document.querySelectorAll(".tooltipped");
    let instance = M.Tooltip.init(elems, {
      html:
        "<p>Vía Google Classroom.</p>" +
        "<p>El docente sube videos, audios, <br/>presentaciones y enlaces y asigna tareas.</p>" +
        "<p>Tú consumes el contenido cuando y donde <br/>quieras, dentro de la duración del curso.</p>" +
        "<p>Ahí subes tus dudas y tus tareas. <br/>Recibes respuesta en 24 horas.</p>" +
        "<p>Tu docente y tu acuerdan días fijos para <br/>hablar por chat.</p>" +
        "<p>Terminas en el tiempo establecido.</p>",
    });

    instance[0].open();
  };

  return (
    <Fragment>
      <h1
        className="titulo_1_nv center"
        style={{ marginBottom: "0px", marginTop: "15px" }}
      >
        {titulo}
      </h1>

      {!flag ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Fragment>
          {/* <div className="container center">
            <p
              className="center titulo_4_nv"
              style={{ fontFamily: "Montserrat-Bold", marginBottom: "0px" }}
            >
              ¿Qué modalidad deseas?
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div className="flex_d1 hijo_1_d3_nv" style={{ width: "100%" }}>
                <div className="hijo_2_d3">
                  <button
                    onClick={() => seleccionarModalidad("1")}
                    className="boton_modalidad"
                  >
                    <Presencial />
                    Presencial
                  </button>
                  <p>
                    En nuestras instalaciones, <br />
                    en días y horarios específicos.
                  </p>
                </div>
                <div className="hijo_2_d3">
                  <button
                    onClick={() => seleccionarModalidad("3")}
                    className="boton_modalidad"
                  >
                    <Videorreunion />
                    Videorreunión
                  </button>
                  <p>
                    En línea, en vivo, <br />
                    en días y horarios específicos.
                  </p>
                </div>
                <div className="hijo_2_d3">
                  {window.screen.width < 900 && (
                    <div onClick={verInfo}>
                      <Info />
                    </div>
                  )}
                  <button
                    onClick={() => seleccionarModalidad("4")}
                    className="boton_modalidad tooltipped"
                    data-position="right"
                  >
                    <Google />
                    Google Classroom
                  </button>
                  <p>
                    En línea, sin horarios <br />y por tiempo limitado
                  </p>
                </div>
              </div>
            </div>

            <p
              className="center titulo_4_nv"
              style={{ fontFamily: "Montserrat-Bold", marginBottom: "0px" }}
            >
              ¿Qué formato buscas?
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div className="flex_d1 hijo_1_d3_nv">
                <div className="hijo_2_d3">
                  <button
                    onClick={() => seleccionarFormato("2")}
                    className="boton_formato"
                  >
                    <Curso />
                    Cursos
                  </button>
                  <p>Son más teóricos</p>
                </div>
                <div className="hijo_2_d3">
                  <button
                    onClick={() => seleccionarFormato("1")}
                    className="boton_formato"
                  >
                    <Taller />
                    Talleres
                  </button>
                  <p>
                    Combinan la teoría <br />
                    con la práctica
                  </p>
                </div>
                <div className="hijo_2_d3">
                  <button
                    onClick={() => seleccionarFormato("3")}
                    className="boton_formato"
                  >
                    <Diplomado />
                    Diplomados
                  </button>
                  <p>
                    Son programas más <br />
                    largos, estructurados <br />
                    en unidades
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          <div className="container contenedor_curso">
            <div className="flex_padre_2_d3">
              {!banderaCursos ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      margin: "40px 0 30px 0",
                    }}
                  >
                    <div className="busqueda_cursos">
                      <input
                        onKeyPress={(e) => {
                          enter(e);
                        }}
                        onChange={busqueda}
                        placeholder="Buscar por palabra clave…"
                        id="nombre_materia"
                        name="nombre_materia"
                        type="text"
                        className="validate formulario"
                      />
                      <label for="nombre_materia"></label>
                    </div>
                  </div>

                  <div className="navegacion_cursos">
                    <b>Buscaste</b>
                    <div />
                    <b className={"color_defecto color_" + area}>{titulo}</b>
                    {modalidad !== 0 && <div />}
                    <b style={{ color: "#64C5DD" }}>
                      {modalidadEtiquetas[modalidad]}
                    </b>
                    {formato !== 0 && <div />}
                    <b style={{ color: "#F49A3F" }}>
                      {formatoEtiquetas[formato]}
                    </b>
                  </div>
                  <div
                    id="cursos"
                    className="flex_padre_2_d3"
                    style={{ width: "100%" }}
                  >
                    {!banderaBuscador ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60vh",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : cursos.length > 0 ? (
                      cursoSeleccionado.map((curso) => (
                        <Etiqueta color={color} curso={curso} area={area} />
                      ))
                    ) : (
                      <p
                        className="center titulo_4_nv"
                        style={{
                          fontFamily: "Montserrat-Bold",
                          marginBottom: "0px",
                        }}
                      >
                        Actualmente no hay cursos disponibles en esta área.
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="boton_7_nv">
                      <button
                        onClick={() => {
                          mostrarMas();
                        }}
                      >
                        <p>Ver más</p>
                      </button>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CursosArea;
