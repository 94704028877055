import React, { useEffect } from "react";
import { useState } from "react";
import "./Whatsapp.css";

import icon from "../../Images/icon.png";
import whats from "../../Images/Icon/47.svg";

const Whatsapp = () => {
  const [mensaje, setMensaje] = useState("");

  const gtag_report_conversion = (url) => {
    var callback = function () {
      if (typeof url != "undefined") {
        window.open(url, "_blank");
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-1004461178/hkJJCMqBy9cBEPq4-94D",
      event_callback: callback,
    });
    return false;
  };

  const sendIt = (e) => {
    // https://api.whatsapp.com/send?phone=3322351431&text=hola%20como%20estas
    e.preventDefault();
    if ("" !== mensaje) {
      let mensajeFinal = "";
      mensaje.split(" ").map((palabra, index) => {
        if (index === 0) {
          return (mensajeFinal = mensajeFinal + palabra);
        } else {
          return (mensajeFinal = mensajeFinal + "%20" + palabra);
        }
      });
      const link =
        "https://api.whatsapp.com/send?phone=3322351431&text=" + mensajeFinal;
      gtag_report_conversion(link);
      // window.open(link, "_blank");
    } else {
      console.log("esta vacio");
    }
  };

  const showChat = (e) => {
    e.preventDefault();
    document.getElementById("whatsapp-chat").className = "show";
  };

  const closeChat = (e) => {
    e.preventDefault();
    document.getElementById("whatsapp-chat").className = "hide";
  };

  return (
    <div>
      <div id="whatsapp-chat" className="hide">
        <div className="header-chat">
          <div className="head-home">
            <div className="info-avatar">
              <img src={icon} alt="Universidad de Medios Audiovisuales" />
            </div>
            <p>
              <span className="whatsapp-name">CAAV</span>
              <br />
              <small>Universidad de Medios Audiovisuales</small>
            </p>
          </div>
          <div className="get-new hide">
            <div id="get-label" />
            <div id="get-nama" />
          </div>
        </div>
        <div className="home-chat" />
        <div className="start-chat">
          <div
            pattern="https://elfsight.com/assets/chats/patterns/whatsapp.png"
            className="WhatsappChat__Component-sc-1wqac52-0 whatsapp-chat-body"
          >
            <div className="WhatsappChat__MessageContainer-sc-1wqac52-1 dAbFpq">
              <div
                style={{ opacity: 0 }}
                className="WhatsappDots__Component-pks5bf-0 eJJEeC"
              >
                <div className="WhatsappDots__ComponentInner-pks5bf-1 hFENyl">
                  <div className="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotOne-pks5bf-3 ixsrax" />
                  <div className="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotTwo-pks5bf-4 dRvxoz" />
                  <div className="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotThree-pks5bf-5 kXBtNt" />
                </div>
              </div>
              <div
                style={{ opacity: 1 }}
                className="WhatsappChat__Message-sc-1wqac52-4 kAZgZq"
              >
                <div className="WhatsappChat__Author-sc-1wqac52-3 bMIBDo">
                  CAAV
                </div>
                <div className="WhatsappChat__Text-sc-1wqac52-2 iSpIQi">
                  Hola, buen día. 👋
                  <br />
                  <br />
                  ¿Cómo podemos ayudarte?
                </div>
                <div className="WhatsappChat__Time-sc-1wqac52-5 cqCDVm">
                  {new Date().getHours() + ":" + new Date().getMinutes()}
                </div>
              </div>
            </div>
          </div>
          <div className="blanter-msg">
            <input
              type="text"
              id="chat-input"
              placeholder="Write a response"
              maxLength={120}
              row={1}
              onChange={(e) => {
                setMensaje(e.target.value);
              }}
            />
            <div onClick={sendIt} id="send-it">
              <svg viewBox="0 0 448 448">
                <path d="M.213 32L0 181.333 320 224 0 266.667.213 416 448 224z" />
              </svg>
            </div>
          </div>
        </div>
        <div id="get-number" />
        <div className="close-chat" onClick={closeChat}>
          ×
        </div>
      </div>
      <div className="blantershow-chat pulse" onClick={showChat}>
        <img src={whats} style={{ width: "35px" }} alt="WhatsApp CAAV" />
      </div>
    </div>
  );
};

export default Whatsapp;
