var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
    url_json = url_json.local;
} else {
    url_json = url_json.production;
}

const Pop = {
    obtenerPop: async () => {
        let url = url_json + "/pop-pop/641a164ef967c2f5dc820dd3"

        let request = {
            method: 'GET',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    }
}

export default Pop;