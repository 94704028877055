import React from "react";
import "./Footer.css";

import logo from "../../Images/Icon/42.png";
import tel from "../../Images/Icon/44.svg";
import mail from "../../Images/Icon/43.svg";
import ubi from "../../Images/Icon/46.svg";
//redes
import face from "../../Images/Icon/40.svg";
import insta from "../../Images/Icon/41.svg";
import twit from "../../Images/Icon/45.svg";
import you from "../../Images/Icon/48.svg";
import whats from "../../Images/Icon/47.svg";

const Footer = () => {
  return (
    <footer
      id="footer"
      className="page-footer"
      style={{ background: "#00496C" }}
    >
      <div id="fondo_curso" style={{ padding: "3px 8%" }}>
        <div className="row" style={{ margin: "0" }}>
          <div className="col s12 m12 l6">
            <div className="col s12 m12 l6" style={{ textAlign: "center" }}>
              <img
                src={logo}
                alt=""
                className="margin_top_1"
                style={{ width: "200px", maxWidth: "350px" }}
              />
            </div>
            <div className="col s12 m12 l6 margin_top_2">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <a
                  target="blank"
                  href="https://www.facebook.com/caavuniversidad"
                  style={{ margin: "5px" }}
                >
                  <img
                    src={face}
                    alt="Pagina Facebook CAAV"
                    style={{ width: "30px" }}
                  />
                </a>
                <a
                  target="blank"
                  href="https://www.instagram.com/caavuniversidad/"
                  style={{ margin: "5px" }}
                >
                  <img
                    src={insta}
                    alt="Perfil Instagram CAAV"
                    style={{ width: "30px" }}
                  />
                </a>
                <a
                  target="blank"
                  href="https://twitter.com/caavuniversidad"
                  style={{ margin: "5px" }}
                >
                  <img
                    src={twit}
                    alt="Perfil Twitter CAAV"
                    style={{ width: "30px" }}
                  />
                </a>
                <a
                  target="blank"
                  href="https://www.youtube.com/c/CaavMxUniversidad"
                  style={{ margin: "5px" }}
                >
                  <img
                    src={you}
                    alt="Canal YouTube CAAV"
                    style={{ width: "30px" }}
                  />
                </a>
                <a
                  target="blank"
                  href="https://wa.me/523322351431?text=¡Hola buen día! ¿Me podrías dar información?"
                  style={{ margin: "5px" }}
                >
                  <img
                    src={whats}
                    alt="Numero WhatsApp CAAV"
                    style={{ width: "30px" }}
                  />
                </a>
              </div>
              <p
                className="invisible_movil"
                style={{
                  fontSize: "15px",
                  margin: "0px",
                  paddingBottom: "10px",
                  textAlign: "center",
                }}
              >
                Copyright © {new Date().getFullYear()} CaavUniversidad
              </p>
            </div>
          </div>
          <div className="col s12 m12 l6">
            <div
              className="col s12 m12 l3 margin_top_3 s_padin_escritorio"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div
                className="col s1 m12 l12 xl12 flex-end"
                style={{
                  margin: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={tel}
                  alt=""
                  className="separar_imagen_footer"
                  style={{ width: "30px" }}
                />
              </div>
              <div
                className="col s11 m12 l12 xl12 flex-start"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  margin: "0",
                }}
              >
                <a
                  target="blank"
                  href="tel:3336152964"
                  style={{ color: "white", marginTop: "20px" }}
                >
                  333 615 2964
                </a>
                <a
                  target="blank"
                  href="tel:3335877825"
                  style={{ color: "white" }}
                >
                  333 587 7825 / 24
                </a>
              </div>
            </div>
            <div
              className="col s12 m12 l5 margin_top_4"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div
                className="col s1 m12 l12 xl12 flex-end"
                style={{
                  margin: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={ubi}
                  alt=""
                  className="separar_imagen_footer sin_separar_movil"
                  style={{ width: "23px" }}
                />
              </div>
              <div
                className="col s11 m12 l12 xl12 flex-start"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  margin: "0",
                }}
              >
                <p className="center_desktop" style={{ color: "white" }}>
                  Lerdo de Tejada 2071, <br />
                  entre Marsella y Av. Chapultepec <br />
                  Colonia Americana, <br />
                  Guadalajara, Jalisco, México.
                </p>
              </div>
            </div>
            <div
              className="col s12 m12 l4 margin_top_2"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div
                className="col s1 m12 l12 xl12 flex-end"
                style={{
                  margin: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={mail}
                  alt="correo"
                  className="separar_imagen_footer sin_separar_movil"
                  style={{ width: "30px" }}
                />
              </div>
              <div
                className="col s11 m12 l12 xl12 flex-start"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  margin: "0",
                }}
              >
                <a
                  target="blank"
                  href="mailto:info@caav.mx?Subject=¡Hola buen día! &body=¡Hola buen día!%0E¿Me podrías dar más información?"
                >
                  <p style={{ color: "white", marginBottom: "0px" }}>
                    info@caav.mx
                  </p>
                </a>
                <a href="/aviso-privacidad">
                  <p style={{ color: "white", marginTop: "5px" }}>
                    Aviso de privacidad.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="invisible_escritorio">
        <p
          style={{
            fontSize: "15px",
            margin: "0px",
            padding: "10px 0 20px 0",
            textAlign: "center",
          }}
        >
          Copyright © {new Date().getFullYear()} CaavUniversidad
        </p>
      </div>
    </footer>
  );
};

export default Footer;
