import React, { Fragment, useEffect, useState } from "react";
import Downloader from "js-file-downloader";
import Swal from "sweetalert2";

import folleto from "../../../Docs/folleto.pdf";

import "./Cine.css";

import video from "../../../Images/escritorio/Licenciaturas/Cine/video/video.m4v";

import logo_2 from "../../../Images/logos/logo_2.svg";
import img1 from "../../../Images/escritorio/Licenciaturas/Cine/2.png";
import icono_1 from "../../../Images/Icon/3.png";
import icono_2 from "../../../Images/Icon/4.png";

import Loader from "../../../Components/Loader/Loader";
import licenciaturasHelpers from "../../../Helpers/Licenciaturas";

const Cine = () => {
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [condiciones, setCondiciones] = useState(false);

  // const gtag_report_conversion_poppop = (url) => {
  //   // proceso de admision
  //   var callback = function () {
  //     if (typeof url != "undefined") {
  //       var nuevaVentana = window.open();
  //       nuevaVentana.location.href = url;

  //       // window.location.replace(url)
  //     }
  //   };
  //   window.gtag("event", "conversion", {
  //     send_to: "AW-1004461178/8ajUCJzJuawYEPq4-94D",
  //     event_callback: callback,
  //   });
  //   return false;
  // };

  useEffect(() => {
    // Swal.fire({
    //   html:
    //     "<p class='texto_pop'><span class='titulo_4_nv titulo_bold'>Entra a la charla informativa</span></br>" +
    //     "de Cine Digital. Martes 11 de marzo a las 5 pm.</p>",
    //   showCloseButton: true,
    //   showCancelButton: false,
    //   focusConfirm: false,
    //   confirmButtonText: '<i class="fa fa-whatsapp"></i> Pide aquí tu acceso',
    //   confirmButtonColor: "#D5193C",
    // }).then((res) => {
    //   if (res.isConfirmed) {
    //     // redireccionamos
    //     // gtag_report_conversion_poppop(
    //     //   "https://api.whatsapp.com/send?phone=3322351431"
    //     // );
    //     var nuevaVentana = window.open();
    //     nuevaVentana.location.href =
    //       "https://api.whatsapp.com/send?phone=3322351431";
    //   }
    // });
    obtenerLicenciatura();
  }, []);

  const obtenerLicenciatura = async () => {
    let res = await licenciaturasHelpers.obtenerUnaLicenciatura(
      "6404b6110bfddd714255dded"
    );

    if (res.success) {
      setData(res.data);
      setFlag(true);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener la licenciatura, codigo: " + res.code,
        "error"
      );
    }
  };

  function createMarkup(materias) {
    return { __html: materias };
  }

  const download = () => {
    new Downloader({
      url: folleto,
    })
      .then(function () {
        // console.log("descargando");
      })
      .catch(function (error) {
        Swal.fire("Error", "Error al descargar archivo", "error");
      });
  };

  // seguimiento de google
  const gtag_report_conversion_descragar = () => {
    // descargar folleto
    var callback = function () {
      if (typeof folleto != "undefined") {
        window.open(folleto, "_blank");
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-1004461178/X1lcCMi9o5AYEPq4-94D",
      event_callback: callback,
    });
    return false;
  };

  const gtag_report_conversion = (url) => {
    // proceso de admision
    var callback = function () {
      if (typeof url != "undefined") {
        window.location.replace(url);
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-1004461178/l1PzCL-9q5AYEPq4-94D",
      event_callback: callback,
    });
    return false;
  };

  return (
    <Fragment>
      {condiciones && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="contenedor_pop_curso"
            onClick={() => {
              setCondiciones(false);
            }}
          ></div>
          <div className="pop_curso">
            <div
              className="pop_salir"
              onClick={() => {
                setCondiciones(false);
              }}
            />
            <p
              className="titulo_pop"
              style={{ color: "#a80b38", textAlign: "center", margin: "0" }}
            >
              ¿Qué validez tienen nuestras licenciaturas?
            </p>
            <p>
              Nuestra Universidad se encuentra incorporada a la Secretaría de
              Innovación, Ciencia y Tecnología de Jalisco (SICyT). En Jalisco,
              desde 2013, esta es la Secretaría que se encarga de dar validez a
              planes y programas de estudios de nivel superior, lo que en otros
              Estados hace la Secretaría de Educación. <br />
              <br />
              Nuestras licenciaturas cuentan con el Reconocimiento de Validez
              Oficial de Estudio RVOE, y Cedula Federal, los cuales puedes
              consultar en el siguiente Link:{" "}
              <a href="https://sicyt.jalisco.gob.mx/educacion-superior/directorio-de-instituciones-con-rvoe">
                https://sicyt.jalisco.gob.mx/educacion-superior/directorio-de-instituciones-con-rvoe
              </a>{" "}
              donde nos puedes buscar con el nombre de fundación de la
              Institución: Centro de Arte Audiovisual.
            </p>
          </div>
        </div>
      )}
      <video style={{ width: "100%" }} id="video" autoPlay muted loop>
        <source src={video} type="video/mp4" />
      </video>
      <div className="container center" style={{ marginBottom: "20px" }}>
        <h1 className="encabezadosCaav" style={{ marginTop: "15px" }}>
          <span className="encabezadosMonserrat">Licenciatura en</span>
          <br />
          Cine Digital
        </h1>
        <img src={logo_2} className="logo_yo_creo" alt="#yoCreo CAAV" />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="titulo_frase">
            Yo creo relatos. <br />
            Yo creo en las emociones. <br />
            Yo creo imágenes que se quedan en el corazón.
          </p>
        </div>
        {!flag ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
            }}
          >
            <Loader />
          </div>
        ) : (
          <iframe
            className="altura_video_licenciatura"
            style={{ width: "100%" }}
            src={data.video}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        <div className="caja_5" style={{ background: "#F2F2F2" }}>
          <div className="texto_licenciatura_carrera">
            <p className="titulo_1_nv" style={{ margin: "0px" }}>
              La carrera
            </p>
            <p>
              El cine es movimiento: imágenes y emociones en movimiento. Es un
              pilar de la cultura contemporánea. El cine conjuga las artes, la
              comunicación y la tecnología. Es fundamental para el mundo del
              entretenimiento, de la comunicación y de la publicidad.
            </p>
            <p>
              Nuestra licenciatura en cine digital es nuestra propuesta para
              narrar historias increíbles, usando toda la tecnología moderna,
              para que lo hagas sin límites.
            </p>
            <p>
              En CAAV entrenamos tu ojo, tu mente y tu intuición, construimos tu
              capacidad narrativa y expandimos tus talentos. Te mostramos muchos
              caminos creativos, para que elijas el que quieras o traces el tuyo
              propio.
            </p>
            <p>
              Esta fascinante licenciatura combina la experiencia práctica y
              teórica de los procesos creativos implicados en la preproducción,
              producción, generación de guion, dirección, fotografía,
              postproducción, edición y tecnología de medios, para la creación
              de obras cinematográficas de corta o larga duración.
            </p>
            <p>
              Al terminar tu licenciatura podrás producir y realizar cine,
              televisión y video, y hacer magníficos productos artísticos,
              educativos, culturales y comerciales.
            </p>
            <p>
              Esta licenciatura está dirigida a personas que sienten una
              profunda necesidad de contar historias y tienen aprecio por las
              manifestaciones artísticas, quienes tienen pasión por el cine y
              sienten amor por la cultura audiovisual, por la música, las
              letras, el arte y la fotografía, y que están dispuestas a invertir
              su tiempo a la disciplina de entrenar sus sentidos y talentos en
              este arte.
            </p>
          </div>
          <div className="texto_licenciatura_carrera">
            <p className="titulo_1_nv left-desktop" style={{ margin: "0px" }}>
              Perfil de ingreso
            </p>
            <div className="flex_padre_licenciatura">
              <div className="flex_hijo_licenciatura_1">
                <p style={{ fontWeight: "bold" }}>Intereses:</p>
                <div className="flex_movil_lic" style={{ textAlign: "left" }}>
                  <div className="texto_s_margen">
                    <p>El cine.</p>
                    <p>Las historias.</p>
                    <p>Las letras.</p>
                    <p>Los personajes.</p>
                    <p>La música.</p>
                    <p>Las artes plásticas.</p>
                    <p>La expresión visual.</p>
                  </div>
                  <div className="texto_s_margen">
                    <p>El movimiento.</p>
                    <p>Los medios digitales.</p>
                    <p>La fotografía.</p>
                    <p>El mundo creativo.</p>
                    <p>La tecnología.</p>
                    <p>Los videojuegos.</p>
                  </div>
                </div>
              </div>
              <div className="flex_hijo_licenciatura_2">
                <p style={{ fontWeight: "bold" }}>Habilidades y destrezas:</p>
                <div
                  className="margin_licenciatura"
                  style={{ textAlign: "left" }}
                >
                  <p className="texto_s_margen">Capacidad de síntesis.</p>
                  <p className="texto_s_margen">Avidez por aprender.</p>
                  <p className="texto_s_margen">
                    Amor por la lectura, la música, las artes.
                  </p>
                  <p className="texto_s_margen">
                    Deseos profundos de contar historias.
                  </p>
                  <p className="texto_s_margen">Pensamiento crítico.</p>
                  <p className="texto_s_margen">Apertura a nuevas ideas.</p>
                  <p className="texto_s_margen">Creatividad.</p>
                  <p className="texto_s_margen">Sensibilidad.</p>
                  <p className="texto_s_margen">Capacidad de análisis.</p>
                  <p className="texto_s_margen">Disciplina.</p>
                  <p className="texto_s_margen">
                    Disposición al trabajo en equipo.
                  </p>
                  <p className="texto_s_margen">
                    Deseos de enfrentar desafíos.
                  </p>
                </div>
              </div>
            </div>
            {/* <img src={img2} alt="" style={{width:"100%"}}/> */}
          </div>
        </div>
      </div>

      <div className="container contenedor_chico" style={{ marginTop: "15px" }}>
        <div className="center">
          <p className="titulo_4_nv" style={{ fontWeight: "bold" }}>
            Tu campo de trabajo
          </p>
          <p>
            En un mundo que cada vez consume y requiere más y más medios
            audiovisuales para el entretenimiento y la vida cotidiana, tu campo
            de trabajo se irá haciendo cada vez más vasto. Por ejemplo:
          </p>
        </div>
        <div style={{ marginLeft: "30px" }}>
          <ul className="puntos">
            <li>Televisoras.</li>
            <li>Casas de animación.</li>
            <li>Diseño de planes de producción.</li>
            <li>Casas de producción cinematográfica.</li>
            <li>Empresas de efectos especiales digitales.</li>
            <li>Empresas desarrolladoras de videojuegos.</li>
            <li>Departamentos de comunicación de empresas y escuelas.</li>
            <li>Dirección de películas, series de televisión, documentales.</li>
            <li>
              Escritura de guiones para series, películas, documentales y
              comerciales.
            </li>
            <li>
              Dirección de videoclips, spots de televisión y cualquier producto
              audiovisual.{" "}
            </li>
            <li>
              Supervisión de cuestiones artísticas, organizativas y comerciales
              de empresas audiovisuales.
            </li>
          </ul>
        </div>
        <p className="center">
          Y vas a tener la visión y capacidad para desarrollar tu propia
          empresa: por ejemplo, una casa de producción cinematográfica, de
          efectos especiales, de casting, de locaciones, de sonido o de
          postproducción.
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <a
          onClick={() => {
            gtag_report_conversion("https://caav.mx/proceso-admision");
          }}
          href="/proceso-admision"
        >
          <div className="boton_licenciatura_2" style={{ marginTop: "0px" }}>
            Conoce el proceso de admisión
          </div>
        </a>
      </div>

      {!flag ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div
          className="center contenedor_d3"
          style={{ background: "#BA2049", color: "#fff" }}
        >
          <p
            className="titulo_5_nv"
            style={{ margin: "0px", fontWeight: "bold" }}
          >
            Inicio de clases:
          </p>
          <p className="titulo_fecha_nv" style={{ margin: "0px" }}>
            {data.inicio}
          </p>
          <p
            className="titulo_5_nv"
            style={{ fontWeight: "bold", marginBottom: "0px" }}
          >
            Próximo inicio
            <br />
            {data.proximo_inicio[0]}
            <br />
            {data.proximo_inicio[1]}
          </p>
        </div>
      )}

      <div
        className="contenedor_2_d3 flex_padre_cine"
        style={{ background: "#E4E4E4" }}
      >
        <div
          className="flex_1"
          style={{
            display: "flex",
            // justifyContent:"center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <img
            src={icono_1}
            alt=""
            className="imagen_lic"
            style={{ fontSize: "14px" }}
          />
          <p className="titulo_4_nv" style={{ marginBottom: "0px" }}>
            ¿Qué validez tienen <br />
            nuestras licenciaturas?
          </p>
          <p style={{ margin: "0" }}>ESLI14201828</p>
          <button
            style={{
              color: "#000",
              border: "none",
              background: "transparent",
              fontWeight: "bold",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setCondiciones(true);
            }}
          >
            Ver detalles.
          </button>
        </div>
        <div
          className="flex_1"
          style={{
            display: "flex",
            // justifyContent:"center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <img
            src={icono_2}
            alt=""
            className="imagen_lic"
            style={{ ontSize: "17px" }}
          />
          <p className="titulo_4_nv" style={{ marginBottom: "0px" }}>
            Horario
          </p>
          <p style={{ marginTop: "0px" }}>
            De lunes a viernes, de 8:00 am a 3:00 pm. <br />
            Pueden programarse algunas sesiones el sábado.
          </p>
        </div>
      </div>

      <div
        className="row"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "stretch",
          marginBottom: "0px",
        }}
      >
        <div className="col s12 m12 l5" style={{ padding: "0px" }}>
          <div style={{ height: "100%" }}>
            <img
              src={img1}
              className="imagen_licenciatura"
              alt="Licenciatura en Cine"
            />
          </div>
        </div>
        <div className="col s12 m12 l7" style={{ background: "#fff" }}>
          <p
            className="encabezadosCaav contenedor_lic_plan"
            style={{ marginTop: "20px", marginBottom: "0px" }}
          >
            Esto es lo que aprenderás <br />
            <span className="encabezadosMonserrat">
              Plan de estudios, en nueve cuatrimestres
            </span>
          </p>
          {!flag ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100%",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div className="flex_2_d3 contenedor_lic_plan">
              <div className="hijo_1_d3">
                <p className="numero_d3_l" style={{ marginBottom: "-10px" }}>
                  1.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[0].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_l" style={{ marginBottom: "-10px" }}>
                  2.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[1].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_l" style={{ marginBottom: "-10px" }}>
                  3.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[2].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_l" style={{ marginBottom: "-10px" }}>
                  4.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[3].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_l" style={{ marginBottom: "-10px" }}>
                  5.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[4].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_l" style={{ marginBottom: "-10px" }}>
                  6.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[5].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_l" style={{ marginBottom: "-10px" }}>
                  7.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[6].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_l" style={{ marginBottom: "-10px" }}>
                  8.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[7].data)}
                  className="texto_lic_8"
                />
              </div>
              <div className="hijo_1_d3">
                <p className="numero_d3_l" style={{ marginBottom: "-10px" }}>
                  9.
                </p>
                <div
                  dangerouslySetInnerHTML={createMarkup(data.semestre[8].data)}
                  className="texto_lic_8"
                />
              </div>
              <div
                className="col s12"
                style={{ margin: "30px 0px", padding: "0px" }}
              >
                <button
                  onClick={gtag_report_conversion_descragar}
                  className="boton_licenciatura_2"
                >
                  Descargar folleto
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Cine;
