import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import Etiqueta from "../../Components/Cursos/Etiqueta";
import Loader from "../../Components/Loader/Loader";

import EtiquetasHelper from "../../Helpers/Etiquetas";
import CursosHelper from "../../Helpers/Cursos";

const CursosRelacion = () => {
  const area = sessionStorage.getItem("area");
  let { id } = useParams("area");
  const [etiqueta, setEtiqueta] = useState([]);

  const [data, setData] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [banderaBuscador, setBanderaBuscador] = useState(true);
  const [banderaCursos, setBanderaCursos] = useState(true);
  const [flag, setFlag] = useState(false);
  const [cursoSeleccionado, setCursoSeleccion] = useState([]);
  const [indexCurso, setIndexCurso] = useState(0);

  useEffect(() => {
    sessionStorage.removeItem("modalidad");
    sessionStorage.removeItem("formato");
    obtenerCursos();
  }, []);

  const obtenerCursos = async () => {
    let res = await CursosHelper.obtenerUnCursoRelacion(id);

    if (res.success) {
      let arrayTemporal = [];
      let arrayCursos = [];
      let indexCurso = 0;

      let ordenado = res.data.sort((x, y) => {
        var firstDate = new Date(x.fechas[0].fecha_inicio);
        var secondDate = new Date(y.fechas[0].fecha_inicio);

        // Evaluar el campo estado_relampago.fecha_fin
        if (x.estado_relampago && x.estado_relampago.fecha_fin) {
          var currentDate = new Date();
          var xFechaFin = new Date(x.estado_relampago.fecha_fin);

          // Comprobar si x.estado_relampago.fecha_fin no ha pasado
          if (xFechaFin > currentDate) {
            // x.estado_relampago.fecha_fin no ha pasado, colocar x al comienzo
            return -1;
          }
        }

        if (firstDate < secondDate) return -1;
        if (firstDate > secondDate) return 1;
        return 0;
      });

      ordenado.map((curso, index, array) => {
        arrayTemporal.push(curso);
        indexCurso = indexCurso + 1;
        if (indexCurso % 8 === 0 && indexCurso !== 0) {
          arrayCursos.push(arrayTemporal);
          arrayTemporal = [];
          indexCurso = 0;
        } else if (index + 1 === array.length) {
          arrayCursos.push(arrayTemporal);
          arrayTemporal = [];
        }
      });

      setCursos(arrayCursos);
      setCursoSeleccion(arrayCursos[0]);
      setData(res.data);
      obtenerEtiqueta();
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener el directorio, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerEtiqueta = async (ids) => {
    let res = await EtiquetasHelper.obtenerUnaEtiqueta(id);

    if (res.success) {
      setEtiqueta(res.data);
      setFlag(true);
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener las etiquetas, codigo: " + res.code,
        "error"
      );
    }
  };

  const mostrarMas = (clic) => {
    if (cursos.length === indexCurso + 1 && !cursos[indexCurso + 1]) {
      Swal.fire("Se acabaron", "Son todos los cursos del CAAV", "success");
    } else {
      let masCursos = cursoSeleccionado;
      let index = indexCurso + 1;
      cursos[index].map((curso) => {
        masCursos.push(curso);
      });
      setCursoSeleccion(masCursos);
      setIndexCurso(index);
    }
  };

  const busqueda = (e) => {
    setBanderaBuscador(false);
    let letras = e.target.value;
    let cursoFiltro = [...data];
    setTimeout(() => {
      let arrayTemporal = [];
      let arrayCursos = [];
      let indexCurso = 0;
      if (letras === "") {
        data.map((curso, index, array) => {
          arrayTemporal.push(curso);
          indexCurso = indexCurso + 1;
          if (indexCurso % 8 === 0 && indexCurso !== 0) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
            indexCurso = 0;
          } else if (index + 1 === array.length) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
          }
        });
        setCursos(arrayCursos);
        setCursoSeleccion(arrayCursos[0]);
        setBanderaBuscador(true);
      } else {
        cursoFiltro.filter((curso, index, array) => {
          // Eliminar acentos del nombre del curso y de la búsqueda
          const nombreSinAcentos = curso.nombre
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          const letrasSinAcentos = letras
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          // Realizar la búsqueda ignorando acentos
          let busqueda = nombreSinAcentos
            .toUpperCase()
            .indexOf(letrasSinAcentos.toUpperCase());

          if (busqueda >= 0) {
            indexCurso = indexCurso + 1;
            arrayTemporal.push(curso);
          }

          // Agrupar los resultados en bloques de 8 elementos
          if (indexCurso % 8 === 0 && indexCurso !== 0) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
            indexCurso = 0;
          } else if (index + 1 === array.length) {
            // Manejar los elementos restantes al final del array
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
          }
        });

        setCursos(arrayCursos);
        setCursoSeleccion(arrayCursos[0]);
        setBanderaBuscador(true);
      }
    }, 1000);
  };

  const enter = (e) => {
    let tecla = document.all ? e.keyCode : e.which;
    if (tecla == 13) {
      window.location.replace("#cursos");
    }
  };

  return (
    <Fragment>
      {!flag ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Fragment>
          <h1
            className="titulo_1_nv center"
            style={{ marginBottom: "0px", marginTop: "40px" }}
          >
            {etiqueta.nombre}
          </h1>

          <div className="container contenedor_curso">
            <div className="flex_padre_2_d3">
              {!banderaCursos ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      margin: "40px 0 60px 0",
                    }}
                  >
                    <div className="busqueda_cursos">
                      <input
                        onKeyPress={(e) => {
                          enter(e);
                        }}
                        onChange={busqueda}
                        placeholder="Buscar por palabra clave…"
                        id="nombre_materia"
                        name="nombre_materia"
                        type="text"
                        className="validate formulario"
                      />
                      <label for="nombre_materia"></label>
                    </div>
                  </div>

                  <div className="navegacion_cursos navegacion_ancho">
                    <b>Buscaste</b>
                    <div />
                    <b className={"color_defecto color_" + area}>
                      {etiqueta.nombre}
                    </b>
                  </div>
                  {/* <p className='encabezadosCaav center' style={{marginTop:"15px"}}>
                                        <span className='encabezadosMonserrat'>
                                            Si no sabes por dónde empezar, aquí están nuestros
                                        </span><br />
                                        Próximos inicios
                                    </p> */}
                  <div
                    id="cursos"
                    className="flex_padre_2_d3"
                    style={{ width: "100%" }}
                  >
                    {!banderaBuscador ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60vh",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : cursos.length > 0 ? (
                      cursoSeleccionado.map((curso) => (
                        <Etiqueta curso={curso} />
                      ))
                    ) : (
                      <p
                        className="center titulo_4_nv"
                        style={{
                          fontFamily: "Montserrat-Bold",
                          marginBottom: "0px",
                        }}
                      >
                        Actualmente no hay cursos disponibles en esta área.
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="boton_7_nv">
                      <button
                        onClick={() => {
                          mostrarMas();
                        }}
                      >
                        <p>Ver más</p>
                      </button>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CursosRelacion;
