import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";

import ProfesoresCursosHelpers from "../../Helpers/ProfesoresCursos";
import CountdownTimer from "../Baner/CountdownTimer ";

const Etiqueta = ({ color, curso, area = "defecto" }) => {
  const areas = [
    "",
    "Animación, Animé y Cómic",
    "Audio y Voz",
    "Cine y Video",
    "Artes plásticas",
    "Diseño",
    "Emprendimiento",
    "Escritura",
    "Fotografía",
    "Teoría del arte",
    "Marketing y la Web",
    "Multimedia y Videojuegos",
    "Suite de Adobe",
    "Artes escénicas",
  ];
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  const [profesoresCursos, setProfesoresCursos] = useState([]);

  const isEstadoRelampago =
    curso.estado_relampago !== undefined &&
    new Date(curso.estado_relampago.fecha_fin).getTime() > new Date().getTime();

  useEffect(() => {
    obtenerProfesoresCursos();
  }, []);

  const obtenerProfesoresCursos = async () => {
    let res = await ProfesoresCursosHelpers.obtenerProfesores();
    if (res.success) {
      setProfesoresCursos(res.data);
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener los profesores, codigo: " + res.code,
        "error"
      );
    }
  };

  function createMarkup(materias) {
    return { __html: materias };
  }

  const obtenerProfesor = (id) => {
    let res = profesoresCursos.filter((profesor) => profesor._id === id);

    if (res.length > 0) {
      return res[0];
    } else {
      return {
        nombre: "- informacion invalida -",
        certificado: "- informacion invalida -",
        imagen: "- informacion invalida -",
      };
    }
  };

  const numeroFormateado = (numero) => {
    let numeroFormateado = parseInt(numero).toLocaleString();

    return numeroFormateado;
  };

  return (
    <div className="hijo_3_d3 hover-3">
      <div className="etiqueta-cursos">
        {isEstadoRelampago && (
          <div
            style={{
              background: color,
              color: "#fff",
              borderRadius: "15px 15px 0 0",
              textAlign: "center",
              padding: "5px 0",
            }}
          >
            <b>
              Oferta relámpago{" "}
              <CountdownTimer fecha={curso.estado_relampago.fecha_fin} />
            </b>
          </div>
        )}
        <div>
          <a href={`/curso/${curso.nombre}`}>
            <div
              className="imagen-hover-3"
              style={{
                color: "black",
                borderRadius: isEstadoRelampago ? "0" : "15px 15px 0 0",
              }}
            >
              <div className="contenedor-hover-3">
                <img
                  src={curso.imagen_externa}
                  alt={curso.nombre}
                  style={{
                    width: "100%",
                    borderBottom: "solid 2px #707070",
                    borderRadius: isEstadoRelampago ? "0" : "15px 15px 0 0",
                  }}
                />
              </div>
            </div>
          </a>
        </div>
        {curso.modalidad.includes("1") && (
          <div className="tipo_curso tipo_precencial">
            <p>Presencial</p>
          </div>
        )}
        {curso.modalidad.includes("2") && (
          <div className="tipo_curso tipo_hibrido">
            <p>Híbrido</p>
          </div>
        )}
        {curso.modalidad.includes("3") && (
          <div className="tipo_curso tipo_video">
            <p>Videorreunión</p>
          </div>
        )}
        {curso.modalidad.includes("4") && (
          <div className="tipo_curso tipo_classroom">
            <p>Classroom</p>
          </div>
        )}

        {/* formato de enseñanza */}
        {curso.formato.includes("1") && (
          <div className="tipo_curso_2 tipo_talleres">
            <p>Taller</p>
          </div>
        )}
        {curso.formato.includes("2") && (
          <div className="tipo_curso_2 tipo_cursos">
            <p>Curso</p>
          </div>
        )}
        {curso.formato.includes("3") && (
          <div className="tipo_curso_2 tipo_diplomados">
            <p>Diplomado</p>
          </div>
        )}
        {/* fin de formato de enseñanza */}

        <div
          style={{
            padding: "30px 10px 10px 10px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "1.125rem",
                fontWeight: "bolder",
                margin: "0",
                color: "#404040",
                height: "75px",
              }}
            >
              {curso.nombre}
            </p>
            <div>
              {curso.area.length > 1 ? (
                <p className={"curso_d3 color_defecto color_" + area}>
                  Multiples áreas
                </p>
              ) : (
                curso.area.map((numero_area) => (
                  <p className={"curso_d3 color_defecto color_" + area}>
                    {areas[numero_area]}
                  </p>
                ))
              )}
              <p className="curso_impartido_d3">
                {obtenerProfesor(curso.fechas[0].tutor).nombre}
              </p>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  curso.descripcion_externa
                )}
              ></div>
            </div>
          </div>
          <div>
            <div style={{ fontSize: "11px" }}>
              {/* <p className='curso_titulo_d3' style={{fontSize:"20px"}}>${curso.data_pago.costo_total}</p> */}
              {curso.data_pago.costo_contado ? (
                <p className="curso_pagos_d3" style={{ color: "#A80938" }}>
                  Pago de contado $
                  {numeroFormateado(curso.data_pago.costo_contado)}
                </p>
              ) : (
                <p className="curso_pagos_d3" style={{ color: "#fff" }}>
                  .
                </p>
              )}
              {curso.data_pago.costo_total &&
                curso.data_pago.costo_contado === undefined && (
                  <p className="curso_pagos_d3" style={{ color: "#A80938" }}>
                    Pago de contado $
                    {numeroFormateado(curso.data_pago.costo_total)}
                  </p>
                )}
              {curso.data_pago.costo_mensual ? (
                <Fragment>
                  <p className="curso_pagos_d3">O tres pagos:</p>
                  <p className="curso_pagos_d3">
                    Pago inicial $
                    {numeroFormateado(curso.data_pago.costo_mensual / 2)}
                  </p>
                  <p className="curso_pagos_d3">
                    Más dos mensualidades de $
                    {numeroFormateado(curso.data_pago.costo_mensual / 4)}
                  </p>
                </Fragment>
              ) : (
                <p className="curso_pagos_d3" style={{ color: "#fff" }}>
                  .
                </p>
              )}
              {curso.data_pago.costo_sabatino && (
                <p className="curso_pagos_d3">Consulta metodologia de pago.</p>
              )}

              {
                <p style={{ marginTop: "0px", fontSize: "11px" }}>
                  Del {new Date(curso.fechas[0].fecha_inicio).getDate()} de{" "}
                  {meses[new Date(curso.fechas[0].fecha_inicio).getMonth()]} al{" "}
                  {new Date(curso.fechas[0].fecha_fin).getDate()} de{" "}
                  {meses[new Date(curso.fechas[0].fecha_fin).getMonth()]} de{" "}
                  {new Date(curso.fechas[0].fecha_fin).getFullYear()}
                </p>
              }
            </div>

            <div
              className="center"
              style={{ marginBottom: "20px", marginTop: "30px" }}
            >
              <a
                href={`/curso/${curso.nombre}`}
                className={
                  "curso_boton_d3 background_defecto background_" + area
                }
              >
                Conoce más
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Etiqueta;
