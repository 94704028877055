import Swal from 'sweetalert2';

var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
    url_json = url_json.local;
} else {
    url_json = url_json.production;
}

const ReinscripcionDataHelper = {
    ObtenerReinscripcionData: async () => {
        let url = url_json + "/reinscripciones-data"
        let request = {
            method: 'GET',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    },
    ObtenerUnReinscripcionData: async (id) => {
        let url = url_json + "/reinscripcion-data/" + id;
        let request = {
            method: 'GET',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    },
    AgregarReinscripcionData: async (state) => {
        let url = url_json + "/agregar-reinscripcion-data";
        let body = {
            archivado: state.archivado,
            celular: state.celular,
            ciudad: state.ciudad,
            colonia: state.colonia,
            condicion: state.condicion,
            condicionEspecial: state.condicionEspecial,
            codigoPostal: state.cp,
            cuatrimestre: state.cuatrimestre,
            domicilio: state.domicilio,
            domicilioEmpresa: state.domicilioEmpresa,
            email: state.email,
            empresa: state.empresa,
            enfermedad: state.enfermedad,
            enfermedadEspecificacion: state.enfermedadEspecificacion,
            estado: state.estado,
            estadoAlumno: state.estadoAlumno,
            fecha: state.fecha,
            grupo: state.grupo,
            licenciatura: state.licenciatura,
            nombre: state.nombre,
            nombreMadre: state.nombreMadre,
            nombrePadre: state.nombrePadre,
            pago: state.pago,
            pais: state.pais,
            pdfs: state.pdfs,
            periodo: state.periodo,
            telefonoEmpresa: state.telefonoEmpresa,
            telefonoMadre: state.telefonoMadre,
            telefonoPadre: state.telefonoPadre
        }
        
        let request = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }
        let response = await fetch(url, request);
        let res = await response.json();
        if (res.success) {
            Swal.fire(
              'Inserción exitosa!',
              'campo guardado correctamente' ,
              'success'
            ).then(() => {
            //   window.location.reload();
            })
        } else {
            Swal.fire(
                'Error!',
                'No se pudo guardar el campo, codigo: ' + res.code ,
                'error'
            ).then(() => {
                // window.location.reload();
            })
        }
        return res;
    },
    ActualizarReinscripcionData: async (id, state) => {
        let url = url_json + "/actualizar-reinscripcion-data/" + id;
        let body = {
            archivado: state.archivado,
            celular: state.celular,
            ciudad: state.ciudad,
            colonia: state.colonia,
            condicion: state.condicion,
            condicionEspecial: state.condicionEspecial,
            codigoPostal: state.cp,
            cuatrimestre: state.cuatrimestre,
            domicilio: state.domicilio,
            domicilioEmpresa: state.domicilioEmpresa,
            email: state.email,
            empresa: state.empresa,
            enfermedad: state.enfermedad,
            enfermedadEspecificacion: state.enfermedadEspecificacion,
            estado: state.estado,
            estadoAlumno: state.estadoAlumno,
            fecha: state.fecha,
            grupo: state.grupo,
            licenciatura: state.licenciatura,
            nombre: state.nombre,
            nombreMadre: state.nombreMadre,
            nombrePadre: state.nombrePadre,
            pago: state.pago,
            pais: state.pais,
            pdfs: state.pdfs,
            periodo: state.periodo,
            telefonoEmpresa: state.telefonoEmpresa,
            telefonoMadre: state.telefonoMadre,
            telefonoPadre: state.telefonoPadre
        }
        let request = {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }
        let response = await fetch(url, request);
        let res = await response.json();
        if (res.success) {
            Swal.fire(
              'Inserción exitosa!',
              'campo actualizado correctamente' ,
              'success'
            ).then(() => {
            //   window.location.reload();
            })
        } else {
            Swal.fire(
                'Error!',
                'No se pudo actualizar el campo, codigo: ' + res.code ,
                'error'
            ).then(() => {
                // window.location.reload();
            })
        }
        return res;
    },
    EliminarReinscripcionData: async (id) => {
        let url = url_json + "/eliminar-reinscripcion-data/" + id;
        let request = {
            method:'DELETE',
            headers: {
                "Content-type":"application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let json = await response.json();
        if (json.success) {
            Swal.fire(
                'Eliminacion exitosa!',
                'Campo eliminado correctamente',
                'success'
            ).then(e => {
                if (e.isConfirmed || e.isDismissed) {
                    // console.log("entro", json);
                    // window.location.href = "/admin/contactos";
                }
            })
        } else {
            Swal.fire(
                'Error !',
                'No se pudo eliminar el campo',
                'error'
            )
        }
        return json;
    }
}

export default (ReinscripcionDataHelper);