var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
  url_json = url_json.local;
} else {
  url_json = url_json.production;
}

const Eetiquetas = {
  obtenerEtiquetas: async () => {
    let url = url_json + "/etiquetas";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerEtiquetasIDs: async (ids) => {
    let url = url_json + "/etiquetas-ids";
    let body = {
      ids,
    };

    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerUnaEtiqueta: async (id) => {
    let url = url_json + "/etiqueta/" + id;
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
};

export default Eetiquetas;
