import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// *** componentes ***  //
import NavBar from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Redes from "../Components/Redes";

// *** vistas ***  //
import Index from "../Views/Index/Index";
import Somos from "../Views/Somos/Somos";
import Contacto from "../Views/Contacto/Contacto";
import Docentes from "../Views/Docentes/Docentes";

import Cursos from "../Views/Cursos/Cursos";
import CursosArea from "../Views/Cursos/CursosArea";
import CursosDias from "../Views/Cursos/CursosDias";
import CursoProfesor from "../Views/Cursos/CursoProfesor";
import CursosRelacion from "../Views/Cursos/CursosRelacion";
import Curso from "../Views/Cursos/Curso/Curso";

import Becas from "../Views/Becas/Becas";
import Entorno from "../Views/Entorno/Entorno";
import Igualdad from "../Views/Igualdad/Igualdad";
import NuevaSede from "../Views/NuevaSede/NuevaSede";

// import Admision from "../Views/Admision/Admision";
import Inscripcion from "../Views/ComoInscribirse/Inscripcion";
// import Preguntas from "../Views/Preguntas/Preguntas";

import Calendario from "../Views/Calendario/Calendario";
import Reinscripcion from "../Views/ComoReinscibirte/Reinscripcion";
import Titulacion from "../Views/Titulacion/Titulacion";

import Blogs from "../Views/Blogs/Blogs";
import BlogsAutores from "../Views/Blogs/BlogsAutores";
import Blog from "../Views/Blogs/Blog/Blog";

// logins
import Horario from "../Views/Login/Horario";
import HorarioExamen from "../Views/Login/HorarioExamen";
import Calificaciones from "../Views/Login/Calificaciones";
import DerechoExamen from "../Views/Login/DerechoExamen";

// licenciaturas
import PDF from "../Views/Login/PDF";
import Cine from "../Views/Licenciaturas/Cine/Cine";
import Animacion from "../Views/Licenciaturas/Animacion/Animacion";
import Multimedia from "../Views/Licenciaturas/Multimedia/Multimedia";
import Marketing from "../Views/Licenciaturas/Marketing/Marketing";

import Error from "../Views/Error/Error";
import Mensaje from "../Views/Mensaje/Mensaje";
import AvisoPrivacidad from "../Views/AvisoPrivacidad/AvisoPrivacidad";

const Router = () => (
  <BrowserRouter>
    <NavBar />
    <Redes />
    <Routes>
      <Route exact path="/" element={<Index />} />
      <Route path="/somos" element={<Somos />} />
      <Route path="/contacto" element={<Contacto />} />
      <Route path="/docentes" element={<Docentes />} />

      <Route path="/cursos-diplomados" element={<Cursos />} />
      <Route path="/cursos-area/:areaTexto" element={<CursosArea />} />
      <Route path="/cursos-en-dias/:dias" element={<CursosDias />} />
      <Route path="/cursos-profesor/:id" element={<CursoProfesor />} />
      <Route path="/cursos-relacion/:id" element={<CursosRelacion />} />
      <Route path="/curso/:nombre" element={<Curso />} />

      <Route path="/beca-caav" element={<Becas />} />
      <Route path="/entorno" element={<Entorno />} />
      <Route path="/igualdad" element={<Igualdad />} />
      <Route path="/nuevocampus" element={<NuevaSede />} />

      <Route path="/caav-blogs" element={<Blogs />} />
      <Route path="/blogs-autor/:autor" element={<BlogsAutores />} />
      <Route path="/caav-blog/:url" element={<Blog />} />

      {/* <Route path="/proceso-admision" element={<Admision />} /> */}
      <Route path="/proceso-admision" element={<Inscripcion />} />
      {/* <Route path="/preguntas-caav" element={<Preguntas />} /> */}

      <Route path="/calendario" element={<Calendario />} />
      <Route path="/reinscripcion" element={<Reinscripcion />} />
      <Route path="/titulaciones" element={<Titulacion />} />

      <Route path="/calificaciones" element={<Calificaciones />} />
      <Route path="/horario" element={<Horario />} />
      <Route path="/horario-examen" element={<HorarioExamen />} />
      <Route path="/derecho-examen" element={<DerechoExamen />} />
      <Route path="/alumnos/pdf-caav" element={<PDF />} />

      {/* licenciaturas */}
      <Route path="/licenciatura-en-cine" element={<Cine />} />
      <Route path="/licenciatura-en-animacion" element={<Animacion />} />
      <Route path="/multimedia" element={<Multimedia />} />
      <Route path="/licenciatura-marketing-digital" element={<Marketing />} />

      <Route path="/mensaje-enviado-curso" element={<Mensaje />} />
      <Route path="/aviso-privacidad" element={<AvisoPrivacidad />} />

      <Route exact path="*" element={<Error />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);

export default Router;
