import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import "./Blogs.css";

import autoresBlogsHelper from "../../Helpers/AutoresBlogs";
import blogsHelper from "../../Helpers/Blogs";
import etiquetasBlogs from "../../Helpers/EtiquetasBlogs";

import Loader from "../../Components/Loader/Loader";
import Principal from "../../Components/Blogs/Principal";
import Reciente from "../../Components/Blogs/Reciente";

const Blogs = () => {
  const { autor } = useParams("autor");

  const [flag, setFlag] = useState(false);
  const [autorData, setAutor] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [blogsMostrar, setBlogsMostrar] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [paginadoIndex, setPaginadoIndex] = useState(1);
  const [paginado, setPaginado] = useState([]);
  const limitePaginado = 6;

  useEffect(() => {
    obtenerBlogs();
  }, []);

  const obtenerBlogs = async () => {
    let res = await blogsHelper.obtenerBlogsAutor(autor);

    if (res.success) {
      // orden de blogs por fechas y limitacion a 4
      let blogsOrden = [...res.data];

      blogsOrden.sort((a, b) => {
        const fechaA = new Date(a.createdAt);
        const fechaB = new Date(b.createdAt);
        if (fechaA > fechaB) {
          return -1;
        }
        if (fechaA < fechaB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      let blogsPaginado = blogsOrden.slice(0, limitePaginado);

      setBlogs(blogsOrden);
      setBlogsMostrar(blogsOrden);
      setPaginado(blogsPaginado);

      obtenerAutor();

      // evaluamos si tiene redireccion por filtro de etiqueta
      if (sessionStorage.getItem("__fl-etq-bg-cv__")) {
        filtroEtiqueta(sessionStorage.getItem("__fl-etq-bg-cv__"), res.data);
        sessionStorage.removeItem("__fl-etq-bg-cv__");
      }

      // evaluamos si tiene redireccion por filtro de tema
      if (sessionStorage.getItem("__fl-tem-bg-cv__")) {
        filtroTema(sessionStorage.getItem("__fl-tem-bg-cv__"), res.data);
        sessionStorage.removeItem("__fl-tem-bg-cv__");
      }
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener los blogs, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerAutor = async () => {
    let res = await autoresBlogsHelper.obtenerUnAutor(autor);

    if (res.success) {
      setAutor(res.data);
      obtenerEtiquetas();
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener el autor, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerEtiquetas = async () => {
    let res = await etiquetasBlogs.obtenerEtiquetas();

    if (res.success) {
      setEtiquetas(res.data);
      setFlag(true);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener las etiquetas, codigo: " + res.code,
        "error"
      );
    }
  };

  const filtroTema = (tema, blogParam = []) => {
    const copiaBlog = blogParam.length > 0 ? [...blogParam] : [...blogs];

    let filtro = copiaBlog.filter(
      (blog) => blog.licenciatura === parseInt(tema)
    );

    let blogsPaginado = filtro.slice(0, 1 * limitePaginado);

    setBlogsMostrar(filtro);
    setPaginado(blogsPaginado);
    setPaginadoIndex(1);
  };

  const filtroEtiqueta = (etiqueta, blogParam = []) => {
    console.log({ etiqueta, blogParam });
    console.log("entro :v");
    const copiaBlog = blogParam.length > 0 ? [...blogParam] : [...blogs];

    console.log({ copiaBlog });

    let filtro = copiaBlog.filter((blog) => blog.etiquetas.includes(etiqueta));

    let blogsPaginado = filtro.slice(0, 1 * limitePaginado);

    setBlogsMostrar(filtro);
    setPaginado(blogsPaginado);
    setPaginadoIndex(1);
  };

  const mostrarMas = () => {
    console.log(paginadoIndex * limitePaginado, blogsMostrar.length);
    if (paginadoIndex * limitePaginado > blogsMostrar.length) {
      return Swal.fire(
        "Upps!",
        "Son todos los blogs que tenemos :D",
        "warning"
      );
    }
    let copiaIndex = paginadoIndex + 1;

    let blogsPaginado = blogsMostrar.slice(0, limitePaginado * limitePaginado);
    setPaginado(blogsPaginado);
    setPaginadoIndex(copiaIndex);
  };

  return (
    <div className="row container">
      <div className="col s12 m12 l4 xl4" style={{ paddingRight: "50px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            margin: "40px 0 60px 0",
          }}
        >
          <div className="busqueda_cursos">
            <input
              placeholder="Buscar por titulo"
              id="nombre_materia"
              name="nombre_materia"
              type="text"
              className="validate formulario"
            />
            <label for="nombre_materia"></label>
          </div>
        </div>
        <p
          className="titulo_4_nv"
          style={{ marginBottom: "0px", color: "#00496C" }}
        >
          Buscar por temas
        </p>
        <div className="row">
          <div className="col s6 boton-licenciatura-blog">
            <button
              onClick={() => {
                filtroTema(0);
              }}
            >
              CINE DIGITAL
            </button>
          </div>
          <div className="col s6 boton-licenciatura-blog">
            <button
              onClick={() => {
                filtroTema(1);
              }}
            >
              MULTIMEDIA
            </button>
          </div>
          <div className="col s6 boton-licenciatura-blog">
            <button
              onClick={() => {
                filtroTema(2);
              }}
            >
              MARKETING DIGITAL
            </button>
          </div>
          <div className="col s6 boton-licenciatura-blog">
            <button
              onClick={() => {
                filtroTema(3);
              }}
            >
              ANIMACIÓN
            </button>
          </div>
        </div>
        {!flag ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "7px" }}>
            {etiquetas.map((etiqueta) => (
              <button
                onClick={() => {
                  filtroEtiqueta(etiqueta._id);
                }}
                className="boton-etiqueta-blog"
              >
                {etiqueta.nombre}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="col s12 m12 l8 xl8">
        <div style={{ textAlign: "center" }}>
          <p className="titulo_1_nv" style={{ marginBottom: "0" }}>
            Explora los temas <br />
            que más te interesan de:
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 auto 25px auto",
            }}
          >
            <img
              style={{ width: "75px", borderRadius: "50%" }}
              src={autorData.imagen}
              alt="autor"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="etiqueta_profesor">{autorData.nombre}</p>
                <p className="etiqueta_fin_profesor">.</p>
              </div>
              <p style={{ margin: "0", color: "#000" }}>
                {autorData.certificado}
              </p>
            </div>
          </div>
        </div>

        {!flag ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <Fragment>
            <div
              className="row"
              style={{ display: "flex", flexWrap: "wrap", gap: "15px 0" }}
            >
              {paginado.map((blog) => (
                <Principal blog={blog} />
              ))}
            </div>
            <button className="boton_2_nv" onClick={mostrarMas}>
              Ver más
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Blogs;
