import React from "react";
import { Carousel, ScrollingCarousel } from "@trendyol-js/react-carousel";

import Arrow from "../../../Images/arrow";

const CarruselPagos = () => {
  return window.innerWidth > 550 ? (
    <Carousel
      show={window.innerWidth > 550 ? window.innerWidth / 420 : 2.1}
      slide={1}
      swiping={false}
      infinite={false}
      rightArrow={
        <button className="btn-izquierda">
          <Arrow width={"30px"} />
        </button>
      }
      leftArrow={
        <button className="btn-derecha">
          <Arrow width={"30px"} rotate={"rotate(180deg)"} />
        </button>
      }
    >
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            En la Caja General,
            <br />
            de la sede Gance
          </p>
          <p>
            <b>
              En efectivo, con cheque
              <br />
              o con tarjeta de crédito o débito,
              <br />
              excepto American Express.
            </b>
            <br />
            <br />
            Abierta de lunes a viernes, de 10 am a 6 pm.
            <br />
            Y sábados de 10 am a 1:30 pm.
            <br />
            <br />
            Está en la Sede Gance
            <br />
            <a
              href="https://maps.app.goo.gl/4dDhoXzdsh9wsvTa7"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#6E6E73", textDecoration: "underline" }}
            >
              Lerdo de Tejada 2091, esquina Marsella.
            </a>
          </p>
        </div>
      </div>
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            Banorte
          </p>
          <p>
            <b>
              Depósito en efectivo
              <br className="invisible_escritorio" />o con cheque.
            </b>
            <br />
            <br />
            <b>Número de empresa:</b>
            <br />
            CEP 62841 (CAAV)
            <br />
            <br />
            <b>Da al cajero:</b>
            <br />
            primer nombre y primer <br className="invisible_escritorio" />
            apellido del aspirante,
            <br />
            seguidos de las letras AB.
          </p>
        </div>
      </div>
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            Multiva
          </p>
          <p>
            <b>Sólo transferencia.</b>
            <br />
            <br />
            <b>Beneficiario:</b>
            <br />
            Centro de Formación y
            <br />
            Capacitación Audiovisual, S.C.
            <br />
            <br />
            <b>RFC.</b> CFC950627R85
            <br />
            <br />
            <b>Email:</b> tesoreria@caav.mx
            <br />
            <br />
            <b>Concepto:</b> Nombre completo
            <br />
            del estudiante de quien realizas el pago.
            <br />
            <br />
            <b>Clabe:</b> 132180000090096391
          </p>
        </div>
      </div>
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            Citibanamex
          </p>
          <p>
            <b>Sólo transferencias electrónicas.</b>
            <br />
            <br />
            <b>Beneficiario:</b> Centro de Formación
            <br />
            y Capacitación Audiovisual, S.C.
            <br />
            <br />
            <b>RFC:</b> CFC950627R85
            <br />
            <br />
            <b>Email:</b> tesoreria@caav.mx
            <br />
            <br />
            <b>Concepto:</b> Nombre completo
            <br />
            del estudiante de quien realizas el pago.
            <br />
            <br />
            <b>Cuenta Clabe:</b> 002320700156748553
          </p>
        </div>
      </div>
      <div style={{ width: "20%" }}></div>
    </Carousel>
  ) : (
    <ScrollingCarousel rightArrow={<div></div>} leftArrow={<div></div>}>
      <div className="card-pago margin-left-ins">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            En la Caja General,
            <br />
            de la sede Gance
          </p>
          <p>
            <b>
              En efectivo, con cheque
              <br />
              o con tarjeta de crédito o débito,
              <br />
              excepto American Express.
            </b>
            <br />
            <br />
            Abierta de lunes a viernes, de 10 am a 6 pm.
            <br />
            Y sábados de 10 am a 1:30 pm.
            <br />
            <br />
            Está en la Sede Gance
            <br />
            <a
              href="https://maps.app.goo.gl/4dDhoXzdsh9wsvTa7"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#6E6E73", textDecoration: "underline" }}
            >
              Lerdo de Tejada 2091, esquina Marsella.
            </a>
          </p>
        </div>
      </div>
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            Banorte
          </p>
          <p>
            <b>
              Depósito en efectivo
              <br className="invisible_escritorio" />o con cheque.
            </b>
            <br />
            <br />
            <b>Número de empresa:</b>
            <br />
            CEP 62841 (CAAV)
            <br />
            <br />
            <b>Da al cajero:</b>
            <br />
            primer nombre y primer <br className="invisible_escritorio" />
            apellido del aspirante,
            <br />
            seguidos de las letras AB.
          </p>
        </div>
      </div>
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            Multiva
          </p>
          <p>
            <b>Sólo transferencia.</b>
            <br />
            <br />
            <b>Beneficiario:</b>
            <br />
            Centro de Formación y
            <br className="invisible_escritorio" />
            Capacitación Audiovisual, S.C.
            <br />
            <br />
            <b>RFC.</b> CFC950627R85
            <br />
            <br />
            <b>Email:</b> tesoreria@caav.mx
            <br />
            <br />
            <b>Concepto:</b> Nombre completo
            <br />
            del estudiante de quien realizas el pago.
            <br />
            <br />
            <b>Clabe:</b> 132180000090096391
          </p>
        </div>
      </div>
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            Citibanamex
          </p>
          <p>
            <b>Sólo transferencias electrónicas.</b>
            <br />
            <br />
            <b>Beneficiario:</b> Centro de Formación
            <br />
            y Capacitación Audiovisual, S.C.
            <br />
            <br />
            <b>RFC:</b> CFC950627R85
            <br />
            <br />
            <b>Email:</b> tesoreria@caav.mx
            <br />
            <br />
            <b>Concepto:</b> Nombre completo
            <br />
            del estudiante de quien realizas el pago.
            <br />
            <br />
            <b>Cuenta Clabe:</b> 002320700156748553
          </p>
        </div>
      </div>
    </ScrollingCarousel>
  );
};

export default CarruselPagos;
