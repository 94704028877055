const eventos = [
  {
    dia: 1,
    mes: 0,
    anio: 2025,
    fecha: "1 de enero del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 13,
    mes: 0,
    anio: 2025,
    fecha: "13 de enero del 2025",
    color: "#FF007E",
    evento: "Primer día de clases",
  },
  {
    dia: 3,
    mes: 1,
    anio: 2025,
    fecha: "3 de febrero del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 24,
    mes: 1,
    anio: 2025,
    fecha: "24 de febrero del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 25,
    mes: 1,
    anio: 2025,
    fecha: "25 de febrero del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 26,
    mes: 1,
    anio: 2025,
    fecha: "26 de febrero del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 27,
    mes: 1,
    anio: 2025,
    fecha: "27 de febrero del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 28,
    mes: 1,
    anio: 2025,
    fecha: "28 de febrero del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 17,
    mes: 2,
    anio: 2025,
    fecha: "17 de marzo del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 11,
    mes: 3,
    anio: 2025,
    fecha: "11 de abril del 2025",
    color: "#4DCC51",
    evento: "Último día de clases",
  },
  {
    dia: 14,
    mes: 3,
    anio: 2025,
    fecha: "14 de abril del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 15,
    mes: 3,
    anio: 2025,
    fecha: "15 de abril del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 16,
    mes: 3,
    anio: 2025,
    fecha: "16 de abril del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 17,
    mes: 3,
    anio: 2025,
    fecha: "17 de abril del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 18,
    mes: 3,
    anio: 2025,
    fecha: "18 de abril del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 19,
    mes: 3,
    anio: 2025,
    fecha: "19 de abril del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 21,
    mes: 3,
    anio: 2025,
    fecha: "21 de abril del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 22,
    mes: 3,
    anio: 2025,
    fecha: "22 de abril del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 23,
    mes: 3,
    anio: 2025,
    fecha: "23 de abril del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 24,
    mes: 3,
    anio: 2025,
    fecha: "24 de abril del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 25,
    mes: 3,
    anio: 2025,
    fecha: "25 de abril del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 28,
    mes: 3,
    anio: 2025,
    fecha: "28 de abril del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 29,
    mes: 3,
    anio: 2025,
    fecha: "29 de abril del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 30,
    mes: 3,
    anio: 2025,
    fecha: "30 de abril del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 29,
    mes: 3,
    anio: 2025,
    fecha: "29 de abril del 2025",
    color: "#5271FF",
    evento: "Último día para reinscribirte",
  },
  {
    dia: 1,
    mes: 4,
    anio: 2025,
    fecha: "1 de mayo del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 2,
    mes: 4,
    anio: 2025,
    fecha: "2 de mayo del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 3,
    mes: 4,
    anio: 2025,
    fecha: "3 de mayo del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 12,
    mes: 4,
    anio: 2025,
    fecha: "12 de mayo del 2025",
    color: "#FF007E",
    evento: "Primer día de clases",
  },
  {
    dia: 23,
    mes: 5,
    anio: 2025,
    fecha: "23 de junio del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 24,
    mes: 5,
    anio: 2025,
    fecha: "24 de junio del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 25,
    mes: 5,
    anio: 2025,
    fecha: "25 de junio del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 26,
    mes: 5,
    anio: 2025,
    fecha: "26 de junio del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 27,
    mes: 5,
    anio: 2025,
    fecha: "27 de junio del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 8,
    mes: 7,
    anio: 2025,
    fecha: "8 de agosto del 2025",
    color: "#4DCC51",
    evento: "Último día de clases",
  },
  {
    dia: 11,
    mes: 7,
    anio: 2025,
    fecha: "11 de agosto del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 12,
    mes: 7,
    anio: 2025,
    fecha: "12 de agosto del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 13,
    mes: 7,
    anio: 2025,
    fecha: "13 de agosto del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 14,
    mes: 7,
    anio: 2025,
    fecha: "14 de agosto del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 15,
    mes: 7,
    anio: 2025,
    fecha: "15 de agosto del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 18,
    mes: 7,
    anio: 2025,
    fecha: "18 de agosto del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 19,
    mes: 7,
    anio: 2025,
    fecha: "19 de agosto del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 20,
    mes: 7,
    anio: 2025,
    fecha: "20 de agosto del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 21,
    mes: 7,
    anio: 2025,
    fecha: "21 de agosto del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 22,
    mes: 7,
    anio: 2025,
    fecha: "22 de agosto del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 19,
    mes: 7,
    anio: 2025,
    fecha: "19 de agosto del 2025",
    color: "#5271FF",
    evento: "Último día para reinscribirte",
  },
  {
    dia: 1,
    mes: 8,
    anio: 2025,
    fecha: "1 de septiembre del 2025",
    color: "#FF007E",
    evento: "Primer día de clases",
  },
  {
    dia: 16,
    mes: 8,
    anio: 2025,
    fecha: "16 de septiembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 13,
    mes: 9,
    anio: 2025,
    fecha: "13 de octubre del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 14,
    mes: 9,
    anio: 2025,
    fecha: "14 de octubre del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 15,
    mes: 9,
    anio: 2025,
    fecha: "15 de octubre del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 16,
    mes: 9,
    anio: 2025,
    fecha: "16 de octubre del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 17,
    mes: 9,
    anio: 2025,
    fecha: "17 de octubre del 2025",
    color: "#0CC0DF",
    evento: "Exámenes parciales",
  },
  {
    dia: 17,
    mes: 10,
    anio: 2025,
    fecha: "17 de noviembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 28,
    mes: 10,
    anio: 2025,
    fecha: "28 de noviembre del 2025",
    color: "#4DCC51",
    evento: "Último día de clases",
  },
  {
    dia: 1,
    mes: 11,
    anio: 2025,
    fecha: "1 de diciembre del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 2,
    mes: 11,
    anio: 2025,
    fecha: "2 de diciembre del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 3,
    mes: 11,
    anio: 2025,
    fecha: "3 de diciembre del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 4,
    mes: 11,
    anio: 2025,
    fecha: "4 de diciembre del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 5,
    mes: 11,
    anio: 2025,
    fecha: "5 de diciembre del 2025",
    color: "#CB6CE6",
    evento: "Exámenes finales",
  },
  {
    dia: 8,
    mes: 11,
    anio: 2025,
    fecha: "8 de diciembre del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 9,
    mes: 11,
    anio: 2025,
    fecha: "9 de diciembre del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 10,
    mes: 11,
    anio: 2025,
    fecha: "10 de diciembre del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 11,
    mes: 11,
    anio: 2025,
    fecha: "11 de diciembre del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 12,
    mes: 11,
    anio: 2025,
    fecha: "12 de diciembre del 2025",
    color: "#FF5757",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 16,
    mes: 11,
    anio: 2025,
    fecha: "16 de diciembre del 2025",
    color: "#5271FF",
    evento: "Último día para reinscribirte",
  },
  {
    dia: 22,
    mes: 11,
    anio: 2025,
    fecha: "22 de diciembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 23,
    mes: 11,
    anio: 2025,
    fecha: "23 de diciembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 24,
    mes: 11,
    anio: 2025,
    fecha: "24 de diciembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 25,
    mes: 11,
    anio: 2025,
    fecha: "25 de diciembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 26,
    mes: 11,
    anio: 2025,
    fecha: "26 de diciembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 27,
    mes: 11,
    anio: 2025,
    fecha: "27 de diciembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 28,
    mes: 11,
    anio: 2025,
    fecha: "28 de diciembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 29,
    mes: 11,
    anio: 2025,
    fecha: "29 de diciembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 30,
    mes: 11,
    anio: 2025,
    fecha: "30 de diciembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
  {
    dia: 31,
    mes: 11,
    anio: 2025,
    fecha: "31 de diciembre del 2025",
    color: "#FFBD59",
    evento: "Día festivo / Vacaciones",
  },
];

export default eventos;
